import Tween from "../utils/Tween";
import { forEach } from "lodash";
import { debugMessage } from "../utils/utils";
let instance = null;

class SoundManager {
  constructor() {
    if (!instance) {
      instance = this;
    }

    return instance;
  }

  init = () => {
    const { camera } = window.bitreelModule.xrScene();
    this.soundMap = new Map(); // change this to a list of map for the showroom
    this.soundLoader = new window.THREE.AudioLoader();
    this.bgListener = new window.THREE.AudioListener();
    this.sfxListener = new window.THREE.AudioListener();
    this.bgPlayer = new window.THREE.Audio(this.bgListener);
    this.bgPlayer.setLoop(true);
    this.bgPlayer.setVolume(1);
    this.bgPlaying = null;
    this.sfxPlayer = new window.THREE.Audio(this.sfxListener);
    this.sfxPlayer.setLoop(false);
    this.sfxPlayer.setVolume(1);
    this.sfxPlaying = null;
    camera.add(this.bgListener);
    camera.add(this.sfxListener);
    const audioSetting = localStorage.getItem("audioSetting");
    if (audioSetting) {
      JSON.parse(audioSetting).muted ? this.muteSound() : this.unMute();
    } else {
      localStorage.setItem("audioSetting", JSON.stringify({ muted: false }));
      this.unMute();
    }
  };

  //TODO : add a load room sound function here to load all the sound of a showroom
  loadFloorSound = (floorData) => {
    forEach(floorData.sound, (sound) => {
      this.soundMap.set(sound.name, this.loadSound(sound.url));
    });
    forEach(floorData.items, (product) => {
      this.soundMap.set(product.shortName, this.loadSound(product.sound.url));
    });
  };

  loadSound = (url) => {
    return new Promise((resolve, reject) => {
      this.soundLoader.load(url, function (buffer) {
        resolve(buffer);
      });
    });
  };
  playLoop = (name) => {
    if (this.bgPlaying == null) {
      var promise = this.soundMap.get(name);
      if (promise) {
        this.bgPlaying = name;
        promise.then((result) => {
          this.bgPlayer.setBuffer(result);
          this.bgPlayer.play();
          this.bgPlayer.source.onended = () => {
            this.bgPlaying = null;
            this.bgPlayer.isPlaying = false;
          };
          debugMessage("playing bg sound : " + name);
        });
      }
    }
  };
  playOnce = (
    name,
    callback = () => {
      return;
    }
  ) => {
    if (this.sfxPlaying == null) {
      var promise = this.soundMap.get(name);
      if (promise) {
        this.sfxPlaying = name;
        promise.then((result) => {
          this.sfxPlayer.setBuffer(result);
          this.sfxPlayer.play();
          !this.mute
            ? Tween.fadeAudio(this.bgPlayer, 0.1, 1000)
            : debugMessage("audio is muted"); // lower the bgPlayer Sound
          debugMessage("playing one time sound: " + name);
          this.sfxPlayer.source.onended = () => {
            !this.mute
              ? Tween.fadeAudio(this.bgPlayer, 1, 1000)
              : debugMessage("audio is muted"); // increase the bgPlayer Sound
            this.sfxPlaying = null;
            this.sfxPlayer.isPlaying = false;
            callback();
          };
        });
      }
    }
  };
  stopLoop = (name = null) => {
    if (this.bgPlaying != null) {
      if (name) {
        if (this.bgPlaying === name) {
          this.bgPlayer.stop();
          this.bgPlaying = null;
        }
      } else {
        this.bgPlayer.stop();
        this.bgPlaying = null;
      }
    }
  };
  stopOnce = (name = null) => {
    if (this.sfxPlaying != null) {
      if (name) {
        if (this.sfxPlaying === name) {
          debugMessage("stop " + name);
          this.sfxPlayer.stop();
          this.sfxPlaying = null;
        }
      } else {
        this.sfxPlayer.stop();
        this.sfxPlaying = null;
      }
      !this.mute
        ? Tween.fadeAudio(this.bgPlayer, 1, 1000)
        : debugMessage("audio is muted");
    }
  };
  stopAll = () => {
    this.stopLoop();
    this.stopOnce();
  };
  setBgVolume = (volume) => {
    this.bgPlayer.setVolume(volume);
  };
  getBgVolume = () => {
    return this.bgPlayer.getVolume();
  };
  setSfxVolume = (volume) => {
    this.sfxPlayer.setVolume(volume);
  };
  getSfxVolume = () => {
    return this.sfxPlayer.getVolume();
  };

  muteSound = () => {
    this.mute = true;
    this.bgPlayer.setVolume(0);
    this.sfxPlayer.setVolume(0);
  };
  unMute = () => {
    this.mute = false;
    if (this.sfxPlaying != null) {
      this.bgPlayer.setVolume(0.1);
    } else {
      this.bgPlayer.setVolume(1);
    }
    this.sfxPlayer.setVolume(1);
  };
}

export default new SoundManager();
