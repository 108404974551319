import React, { Component } from "react";
import "./AudioButton.scss";
import "../../../core/SoundManager";
import SoundManager from "../../../core/SoundManager";

class AudioButton extends Component {
  state = {
    audio: { muted: false }, // may add more in the future
    activeIcon: this.props.audioOnIcon,
  };

  onClick = () => {
    this.setState(
      {
        audio: { muted: !this.state.audio.muted },
        activeIcon: !this.state.audio.muted
          ? this.props.audioOffIcon
          : this.props.audioOnIcon,
      },
      () => {
        this.state.audio.muted
          ? SoundManager.muteSound()
          : SoundManager.unMute();
        localStorage.setItem("audioSetting", JSON.stringify(this.state.audio));
      }
    );
  };

  componentDidMount() {
    const audioSetting = localStorage.getItem("audioSetting");
    if (audioSetting) {
      this.setState({
        audio: JSON.parse(audioSetting),
        activeIcon: JSON.parse(audioSetting).muted
          ? this.props.audioOffIcon
          : this.props.audioOnIcon,
      });
    }
  }

  render() {
    return (
      <div className="audio-button" onClick={this.onClick}>
        {this.state.activeIcon}
      </div>
    );
  }
}

export default AudioButton;
