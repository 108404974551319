import _ from "lodash";

const defaultState = {
  overlayMessage: null,
  mode: "showroom",
  selectedSmartObject: null,
  enteredAR: false,
  objectGazed: null,
  animatedTip: null,
  itemLoading: false,
  addingProduct: false,
  currentSection: null,
  instruction: null,
  currentIndex: 0,
  instructionState: null,
  instructionSkip: false,
  forceHide: false,
  sharePopup: false,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case "UPDATE_SHARE_POPUP": {
      return {
        ...state,
        sharePopup: action.isShareOpen,
      };
    }
    case "UPDATE_CURRENT_SECTION": {
      return {
        ...state,
        currentSection: action.currentSection,
      };
    }
    case "UPDATE_INSTRUCTION_STATE": {
      return {
        ...state,
        instructionState: action.instructionState,
      };
    }
    case "UPDATE_INSTRUCTION": {
      return {
        ...state,
        instruction: action.instruction,
      };
    }
    case "UPDATE_CURRENT_INDEX": {
      return {
        ...state,
        currentIndex: action.currentIndex,
      };
    }
    case "UPDATE_INSTRUCTION_SKIP": {
      return {
        ...state,
        instructionSkip: action.instructionSkip,
      };
    }
    case "UPDATE_OVERLAYMESSAGE": {
      return {
        ...state,
        overlayMessage: action.overlayMessage,
      };
    }
    case "UPDATE_MODE": {
      return {
        ...state,
        mode: action.mode,
      };
    }
    case "UPDATE_SELECTED_SMART_OBJECT": {
      return {
        ...state,
        selectedSmartObject: action.selectedSmartObject,
      };
    }
    case "UPDATE_ENTEREDAR": {
      return {
        ...state,
        enteredAR: action.enteredAR,
      };
    }
    case "UPDATE_OBJECT_GAZED": {
      return {
        ...state,
        objectGazed: action.objectGazed,
      };
    }
    case "UPDATE_ANIMITED_TIP": {
      return {
        ...state,
        animatedTip: action.animatedTip,
      };
    }
    case "UPDATE_ITEM_LOADING": {
      return {
        ...state,
        itemLoading: action.itemLoading,
      };
    }
    case "UPDATE_ADDING_PRODUCT": {
      return {
        ...state,
        addingProduct: action.addingProduct,
      };
    }
    case "UPDATE_FORCE_HIDE": {
      return {
        ...state,
        forceHide: action.forceHide,
      };
    }
    default:
      return state;
  }
}
