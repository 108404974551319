import store from "../../redux";

export default {
  updateInstructionState(state) {
    return {
      type: "UPDATE_INSTRUCTION_STATE",
      instructionState: state,
    };
  },
  updateOverlaymessage(overlayMessage) {
    return {
      type: "UPDATE_OVERLAYMESSAGE",
      overlayMessage: overlayMessage,
    };
  },
  showSharePopup(isShareOpen) {
    return {
      type: "UPDATE_SHARE_POPUP",
      isShareOpen: isShareOpen,
    };
  },
  updateMode(mode) {
    return {
      type: "UPDATE_MODE",
      mode: mode,
    };
  },
  updateInstructionSkip(bool) {
    return {
      type: "UPDATE_INSTRUCTION_SKIP",
      instructionSkip: bool,
    };
  },
  updateCurrentSection(sectionID) {
    return {
      type: "UPDATE_CURRENT_SECTION",
      currentSection: sectionID,
    };
  },
  updateSelectedSmartObject(selectedSmartObject) {
    return {
      type: "UPDATE_SELECTED_SMART_OBJECT",
      selectedSmartObject: selectedSmartObject,
    };
  },
  updateEnteredAR(enteredAR) {
    return {
      type: "UPDATE_ENTEREDAR",
      enteredAR: enteredAR,
    };
  },
  updateObjectGazed(object) {
    return {
      type: "UPDATE_OBJECT_GAZED",
      objectGazed: object,
    };
  },
  updateAnimatedTip(animatedTip) {
    return {
      type: "UPDATE_ANIMITED_TIP",
      animatedTip: animatedTip,
    };
  },
  updateItemLoading(itemLoading) {
    return {
      type: "UPDATE_ITEM_LOADING",
      itemLoading: itemLoading,
    };
  },
  updateAddingProduct(addingProduct) {
    return {
      type: "UPDATE_ADDING_PRODUCT",
      addingProduct: addingProduct,
    };
  },
  updateInstruction(instruction) {
    return {
      type: "UPDATE_INSTRUCTION",
      instruction: instruction,
    };
  },
  updateCurrentIndex(currentIndex) {
    return {
      type: "UPDATE_CURRENT_INDEX",
      currentIndex: currentIndex,
    };
  },
  updateCurrentInstruction(index) {
    return (dispatch) => {
      let instruction = store.getState().UI.instruction;
      if (index < instruction.content.length) {
        dispatch(this.updateCurrentIndex(index));
        dispatch(this.updateInstructionState(instruction.content[index].name));
        if (instruction.autoPlay) {
          setTimeout(() => {
            if (store.getState().UI.currentIndex === index) {
              dispatch(this.updateCurrentInstruction(index + 1));
            }
          }, instruction.content[index].timeout);
        }
      }
    };
  },
  updateForceHide(forceHide) {
    return {
      type: "UPDATE_FORCE_HIDE",
      forceHide: forceHide,
    };
  },
};
