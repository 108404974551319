export function rotate2d(cx, cy, x, y, angle) {
  var radians = (Math.PI / 180) * angle,
    cos = Math.cos(radians),
    sin = Math.sin(radians),
    nx = cos * (x - cx) + sin * (y - cy) + cx,
    ny = cos * (y - cy) - sin * (x - cx) + cy;
  return [nx, ny];
}

export function smooth(values, alpha) {
  var weighted = average(values) * alpha;
  var smoothed = [];
  for (var i in values) {
    var curr = values[i];
    var prev = smoothed[i - 1] || values[values.length - 1];
    var next = curr || values[0];
    var improved = Number(average([weighted, prev, curr, next]).toFixed(2));
    smoothed.push(improved);
  }
  return smoothed;
}

export function average(data) {
  var sum = data.reduce(function (sum, value) {
    return sum + value;
  }, 0);
  var avg = sum / data.length;
  return avg;
}

export function GetMeanVector(positions) {
  if (positions.length == 0) return null;
  let x = 0;
  let z = 0;
  positions.forEach((pos) => {
    x += pos.x;
    z += pos.z;
  });
  return [x / positions.length, z / positions.length];
}

export function otherRotate2D(cx, cy, x, y, angle, anticlock_wise = false) {
  if (angle == 0) {
    return { x, y };
  }
  if (anticlock_wise) {
    var radians = (Math.PI / 180) * angle;
  } else {
    var radians = (Math.PI / -180) * angle;
  }
  var cos = Math.cos(radians);
  var sin = Math.sin(radians);
  var nx = cos * (x - cx) + sin * (y - cy) + cx;
  var ny = cos * (y - cy) - sin * (x - cx) + cy;
  return { x: nx, y: ny };
}
