import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import "./HelpButton.scss";
import UIActions from "../../../redux/actions/UI";

class HelpButton extends Component {
  state = {};

  onClick = () => {
    this.props.updateCurrentInstruction(0);
  };

  componentDidMount() {}

  render() {
    return (
      <div className="help-button" onClick={this.onClick}>
        {this.props.icon}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  instructionState: state.UI.instructionState,
  instructionSkip: state.UI.instructionSkip,
  icon: ownProps.icon,
});

const mapDispatchToProps = (dispatch) => ({
  updateCurrentInstruction: (index) =>
    dispatch(UIActions.updateCurrentInstruction(index)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HelpButton);
