import React, { Component } from "react";
import { ShowroomJoystick, Tooltips } from "../../components";
import "./ShowroomHud.scss";
import ReactGA from "react-ga";
import connect from "react-redux/es/connect/connect";

class ShowroomHud extends Component {
  state = {};

  componentDidMount() {
    setTimeout(() => {
      this.setState(
        {
          mounted: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              mounted: false,
            });
          }, 2000);
        }
      );
    }, 750);

    //ga
    ReactGA.pageview("/showroom");
  }

  render() {
    const { bitreelMain } = this.props;
    return (
      <div className="showroom-hud">
        {this.props.platform === "mobile" ? (
          <ShowroomJoystick bitreelMain={bitreelMain} />
        ) : (
          <></>
        )}
        {this.props.children}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  platform: state.store.platform,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ShowroomHud);
