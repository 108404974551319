import React, { Component } from "react";
import "./AddProductButton.scss";
import connect from "react-redux/es/connect/connect";
import { batch } from "react-redux";
import UIActions from "../../redux/actions/UI";

class AddProductButton extends Component {
  state = {};
  render() {
    return (
      <div
        className={`add-product-button-container ${
          (this.props.selectedSmartObject && !this.props.forceHide) ||
          this.props.addingProduct
            ? "move-up"
            : ""
        }`}
      >
        <div
          style={{ color: this.props.addingProduct ? "red" : "black" }}
          className="add-product-button"
          onClick={() => {
            batch(() => {
              this.props.updateForceHide(!this.props.addingProduct);
              this.props.updateAddingProduct(
                this.props.addingProduct ? false : true
              );
            });
          }}
        >
          <h1>Add product</h1>
          {this.props.addingProduct ? "x" : "+"}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  addingProduct: state.UI.addingProduct,
  forceHide: state.UI.forceHide,
});

const mapDispatchToProps = (dispatch) => ({
  updateAddingProduct: (state) =>
    dispatch(UIActions.updateAddingProduct(state)),
  updateForceHide: (forceHide) =>
    dispatch(UIActions.updateForceHide(forceHide)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddProductButton);
