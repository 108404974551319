import React from "react";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import cartActions from "../../../redux/actions/cart";
import "./ShoppingCartButton.scss";
import { STORAGE_KEY } from "../../../constants";
import { Badge } from "antd";

class ShoppingCartButton extends React.Component {
  static propTypes = {
    isDrawerOpen: PropTypes.bool,
    itemsCount: PropTypes.number,
    openDrawer: PropTypes.func,
    updateLocalStorageCount: PropTypes.func,
    fetchProducts: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const items = window.localStorage.getItem(STORAGE_KEY)
      ? this.props.fetchProducts(
          this.props.ecommConfig.accountId,
          window.localStorage.getItem(STORAGE_KEY)
        )
      : null;
    this.props.openDrawer();
  }

  render() {
    return (
      <>
        {this.props.isDrawerOpen ? null : (
          <div className="cart-button" onClick={this.onClick}>
            <Badge count={this.props.itemsCount} overflowCount={10}>
              {this.props.icon}
            </Badge>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isDrawerOpen: state.cart.isDrawerOpen,
  itemsCount: state.cart.itemsCount,
  icon: ownProps.icon,
  ecommConfig: ownProps.ecommConfig,
  cartItemCount: state.cart.itemsCount,
});

const mapDispatchToProps = (dispatch) => ({
  openDrawer: () => dispatch(cartActions.openDrawer()),
  fetchProducts: (aid, pids) => dispatch(cartActions.fetchProducts(aid, pids)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCartButton);
