import React, { Component } from "react";
import "./ProductBanner.scss";
import connect from "react-redux/es/connect/connect";
import cartActions from "../../redux/actions/cart";
import { STORAGE_KEY } from "../../constants";
import UIActions from "../../redux/actions/UI";
import { message } from "antd";
import { debugMessage } from "../../utils/utils";

class ProductBanner extends Component {
  state = {};

  constructor(props) {
    super(props);
    this.addProduct = this.addProduct.bind(this);
    this.localStorageUpdated = this.localStorageUpdated.bind(this);
  }

  addProduct() {
    debugMessage("adding product,...");
    let products = [];
    if (localStorage.getItem(STORAGE_KEY)) {
      products = JSON.parse(localStorage.getItem(STORAGE_KEY));
    }
    products.push({ pid: this.props.selectedSmartObject });
    localStorage.setItem(STORAGE_KEY, JSON.stringify(products));
    this.localStorageUpdated();
    message.config({
      getContainer: () => this.props.mountPoint,
      duration: 2,
    });
    message.success("Product added to cart");
  }

  localStorageUpdated() {
    const items = localStorage.getItem(STORAGE_KEY);
    if (items !== null) {
      this.props.updateLocalStorageCount(JSON.parse(items).length);
    }
  }

  render() {
    return (
      <div
        className={`${
          this.props.mode === "product" ? "product" : ""
        } product-banner-container ${
          this.props.selectedSmartObject && !this.props.forceHide
            ? "mounted"
            : ""
        }`}
      >
        <div className="product-banner-container__inner">
          {this.props.mode === "product" && (
            <div className="return-to-store">
              <div
                className="button"
                onClick={
                  !this.props.switchingMode
                    ? this.props.bitreelMain.store.enableShowroomView
                    : debugMessage("switching to product view not finished")
                }
              >
                return to store
              </div>
            </div>
          )}
          {this.props.selectedSmartObject && (
            <div className="product-banner-container__inner__top">
              <img src={this.props.productData.imageUrl} />
              <div className="product-banner-container__inner__right">
                <p className="product_banner_name">
                  {this.props.productData.name}
                </p>
                <h2 className="product_banner_price">
                  ${this.props.productData.price}.00
                </h2>
              </div>
            </div>
          )}
          {this.props.selectedSmartObject && (
            <div className="inner_buttons_container">
              {this.props.mode !== "product" && (
                <div
                  onClick={() => {
                    debugMessage("view in AR clicked");
                    !this.props.switchingMode
                      ? this.props.bitreelMain.store.enableProductView(
                          this.props.selectedSmartObject
                        )
                      : debugMessage("switching to showroom not finished");
                  }}
                  className="small-button hud-element"
                  style={{ marginBottom: "5px" }}
                >
                  View in AR
                </div>
              )}
              {/* TODO: this should be disabled classname as well if already in cart.. connect() this component.
                        Quantity should be in the cart */}
              <div
                onClick={this.addProduct}
                className="small-button"
                style={{ marginBottom: "5px" }}
              >
                Add to Cart
              </div>
              <div
                className="small-button disabled"
                style={{ marginBottom: "5px" }}
              >
                Details
              </div>
              {this.props.mode === "product" && (
                <div
                  onClick={() => {
                    debugMessage("delete button clicked");
                    this.props.bitreelMain.store.deleteObject(
                      this.props.selectedSmartObject
                    );
                  }}
                  className="small-button remove-button"
                  style={{ marginBottom: "5px" }}
                >
                  Remove
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  itemsCount: state.cart.itemsCount,
  mode: state.UI.mode,
  addingProduct: state.UI.addingProduct,
  switchingMode: state.store.switchingMode,
  selectedSmartObject: state.UI.selectedSmartObject,
  productData: state.UI.selectedSmartObject
    ? state.store.storeConfig.sections[state.UI.currentSection].items[
        state.UI.selectedSmartObject.productID
      ]
    : null,
  forceHide: state.UI.forceHide,
});

const mapDispatchToProps = (dispatch) => ({
  addProduct: (pid) => dispatch(cartActions.addProduct(pid)),
  updateLocalStorageCount: (num) =>
    dispatch(cartActions.updateLocalStorageCount(num)),
  updateForceHide: (forceHide) =>
    dispatch(UIActions.updateForceHide(forceHide)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductBanner);
