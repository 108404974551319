import { SmartStore } from "../core";
import { debugMessage } from "../utils/utils";

export default class ShowroomLoader {
  constructor({ storeConfig, app }) {
    this.storeConfig = storeConfig;
    this.app = app;
  }

  initStore = () => {
    return new Promise(async (resolve, reject) => {
      this.store = new SmartStore({
        storeConfig: this.storeConfig,
        app: this.app,
      });
      await this.store.init();
      debugMessage("Showroom Init");
      resolve(this.store);
    });
  };

  deinitStore = () => {
    return new Promise(async (resolve, reject) => {
      // tear down the three scene and break umbra connections if needed
      resolve(true);
    });
  };
}
