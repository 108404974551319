import React, { Component } from "react";
import BitreelMain from "../BitreelMain";
import LoadingScreen from "../UI/screens/LoadingScreen";
import storeActions from "../redux/actions/store";
import UIActions from "../redux/actions/UI";
import "./MobileMode.scss";
import {
  OverlayMessage,
  Instruction,
  TipImage,
  ProductBanner,
  Logo,
} from "../UI/components";
import ShowroomHud from "../UI/types/showroom";
import ProductHud from "../UI/types/product";
import GazeIndicator from "../UI/components/GazeIndicator";
import connect from "react-redux/es/connect/connect";
import ItemLoading from "../UI/components/ItemLoading";
import AddProductButton from "../UI/components/AddProductButton";
import { debugMessage } from "../utils/utils";
import SharePopup from "../UI/components/SharePopup";

class MobileMode extends Component {
  startBitreel = () => {
    this.props.updateEnteredAR(true);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const storename_param = urlParams.get("store");

    let storeName;
    if (process.env.NODE_ENV === "production") {
      storeName = this.props.storeName;
    } else {
      storeName = storename_param;
    }
    debugMessage("creating bitreel main");
    this.bitreelMain = new BitreelMain(
      this,
      this.props.storeInfo,
      storeName,
      this.props.storeInfo.meta.defaultFloor,
      this.props.customElement
    );
  };

  render() {
    let that = this;
    if (this.props.storeInfo && this.props.loading) {
      debugMessage("Loading Screen Rendered");
      return (
        <LoadingScreen
          startBitreel={() => {
            debugMessage(that);
            that.startBitreel();
          }}
        />
      );
    }
    return (
      <div className="mobile-mode">
        {!this.props.loading &&
          !(
            this.props.instructionState === "end" && this.props.instructionSkip
          ) && (
            <Instruction
              instruction={{
                autoPlay: true,
                content: [
                  { name: "start", timeout: 100, forceAction: false }, // Force Action is for future if we want the user to perform the instruction action
                  { name: "audio", timeout: 3000, forceAction: false },
                  { name: "setting", timeout: 3000, forceAction: false },
                  {
                    name: "joystickMove",
                    timeout: 4500,
                    forceAction: false,
                  },
                  {
                    name: "joystickRotate",
                    timeout: 4500,
                    forceAction: false,
                  },
                  { name: "end", timeout: 0, forceAction: false },
                ],
              }}
              icon={<i className="fa fa-bars" />}
            />
          )}
        {!this.props.loading &&
          this.props.instructionState === "end" &&
          this.props.instructionSkip && <Logo />}
        {!this.props.loading && (
          <ProductBanner
            selectedSmartObject={this.props.selectedSmartObject}
            bitreelMain={this.bitreelMain}
            mountPoint={
              this.props.customElement
                ? this.props.customElement.mountPoint
                : document.body
            }
          />
        )}
        {!this.props.loading && this.props.mode === "showroom" && (
          <ShowroomHud bitreelMain={this.bitreelMain}>
            {this.props.renderViewerMenuComponents()}
          </ShowroomHud>
        )}
        {!this.props.loading && this.props.mode === "product" && (
          <ProductHud
            ref={(ref) => (this.productHud = ref)}
            selectedSmartObject={this.props.selectedSmartObject}
            bitreelMain={this.bitreelMain}
            addingProduct={this.props.addingProduct}
            selectedSmartObject={this.props.selectedSmartObject}
          >
            {this.props.renderViewerMenuComponents()}
          </ProductHud>
        )}
        {!this.props.loading && this.props.mode === "product" && (
          <AddProductButton
            selectedSmartObject={this.props.selectedSmartObject}
          />
        )}
        {!this.props.loading && this.props.itemLoading && <ItemLoading />}
        {this.props.animatedTip && <TipImage />}
        {this.props.overlayMessage && (
          <OverlayMessage body={this.props.overlayMessage} />
        )}
        {!this.props.loading &&
          this.props.mode === "showroom" &&
          this.props.objectGazed && <GazeIndicator />}
        <SharePopup />
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  loading:
    state.store.storeConfigLoading ||
    state.store.productsConfigLoading ||
    !state.store.storeReady,
  storeInfo: state.store.storeConfig,
  enteredAR: state.UI.enteredAR,
  mode: state.UI.mode,
  selectedSmartObject: state.UI.selectedSmartObject,
  objectGazed: state.UI.objectGazed,
  animatedTip: state.UI.animatedTip,
  addingProduct: state.UI.addingProduct,
  itemLoading: state.UI.itemLoading,
  overlayMessage: state.UI.overlayMessage,
  instructionState: state.UI.instructionState,
  instructionSkip: state.UI.instructionSkip,
});

const mapDispatchToProps = (dispatch) => ({
  fetchStoreConfig: (storename) =>
    dispatch(storeActions.fetchStoreConfig(storename)),
  updateStoreConfig: (key, value) =>
    dispatch(storeActions.updateStoreConfig(key, value)),
  updateEnteredAR: (enter) => dispatch(UIActions.updateEnteredAR(enter)),
  updateLoading: (isLoading) => dispatch(UIActions.updateLoading(isLoading)),
  updateObjectGazed: (smartObject) =>
    dispatch(UIActions.updateObjectGazed(smartObject)),
  updateOverlayMessage: (message) =>
    dispatch(UIActions.updateOverlaymessage(message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileMode);
