import React from "react";

export default {
  SHOPPING_CART: "Shopping cart",
  SUBTOTAL: "Subtotal",
  CHECKOUT: "Checkout Disabled",
  EMPTY: "Empty, please continue shopping...",
  DESK_QR_INSTRUCTION_SUPPORT:
    "Augmented reality experience is currently supported on the following device: iPhone on iOS 12+ Android 8.0+ with ARCore 1.9 support",
  DESK_QR_TITLE: "Scan QR Code",
  DESK_QR_INSTRUCTION:
    "Open your mobile device's camera and point it at the QR code above to see this showroom come to life in augmented reality!",
  SHARE_MODAL:
    "Excited? Please share this showroom to following social platforms:",
  FACEBOOK_HASHTAG: "#ar#vr#arshowroom#armodel#furniture",
  FACEBOOK_QUOTE: "AR Showroom -- Your Immersive Shopping Experience",
  LINKEDIN_TITLE: "AR Showroom -- Your Immersive Shopping Experience",
  LINKEDIN_SUMMARY:
    "Using augmented reality to create a more personalized shopping experience online.",
  LINKEDIN_SOURCE: "https://www.bitreel.com",
  TWITTER_TITLE: "AR Showroom -- Your Immersive Shopping Experience",
  TWITTER_HASHTAGS: [
    "ar",
    "vr",
    "arshowroom",
    "armodel",
    "furniture",
    "design",
  ],
  TWITTER_RELATED: ["@bitreel"],
};
