import { GetMeanVector } from "./utils/mathUtils";
import { debugMessage } from "./utils/utils";

export default (startupFunction, main) => {
  let app = main;
  let scene3;
  let _8thwallScaleMultiplier = 0.6;
  let _8thwallPositions = [];
  debugMessage("creating 8thwall module");
  return {
    name: "customthreemodule",
    onStart: async ({ canvas, canvasWidth, canvasHeight, GLctx }) => {
      debugMessage("module startup begin");
      console.log(canvas);
      const scene = new window.THREE.Scene();
      const camera = new window.THREE.PerspectiveCamera(
        90.0,
        canvasWidth / canvasHeight,
        1,
        10000
      );
      let cameraChild = new window.THREE.Object3D();
      camera.add(cameraChild);
      scene.add(camera);

      // context: GLctx,
      const renderer = new window.THREE.WebGLRenderer({
        canvas,
        alpha: false,
        antialias: true,
      });
      renderer.autoClear = false;
      renderer.setPixelRatio(window.devicePixelRatio * 0.4);
      renderer.setSize(canvasWidth, canvasHeight);
      // TODO: FIX THIS
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const storename = urlParams.get("store");

      if (storename !== "boconcept") {
        renderer.toneMapping = window.THREE.ACESFilmicToneMapping;
      }

      let cameraProxy = new window.THREE.Object3D();
      scene.add(cameraProxy);

      scene3 = { scene, camera, renderer, cameraChild, cameraProxy };

      window.XR8.XrController.updateCameraProjectionMatrix({
        origin: camera.position,
        facing: camera.quaternion,
        cam: {
          pixelRectWidth: canvasWidth,
          pixelRectHeight: canvasHeight,
          nearClipPlane: 0.01,
          farClipPlane: 10000,
        },
      });
      debugMessage("module startup complete");
      startupFunction();
    },
    onUpdate: (ev) => {
      let processCpuResult = ev.processCpuResult;
      if (!processCpuResult.reality) {
        return;
      }
      const {
        rotation,
        position,
        intrinsics,
        points,
        worldPoints,
      } = processCpuResult.reality;
      const { camera, cameraProxy } = scene3;

      for (let i = 0; i < 16; i++) {
        camera.projectionMatrix.elements[i] = intrinsics[i];
      }

      // Default 8th wall three module isn't doing this
      // TODO: Do we need this ? -MT
      camera.projectionMatrixInverse.getInverse(camera.projectionMatrix);

      if (rotation) {
        camera.setRotationFromQuaternion(rotation);
      }

      if (position) {
        if (_8thwallPositions.length > 5) {
          _8thwallPositions.push({
            x: position.x * _8thwallScaleMultiplier,
            z: position.z * _8thwallScaleMultiplier,
          });
          _8thwallPositions.shift();
        } else {
          _8thwallPositions.push({
            x: position.x * _8thwallScaleMultiplier,
            z: position.z * _8thwallScaleMultiplier,
          });
        }
        const meanVector = GetMeanVector(_8thwallPositions);

        camera.position.set(
          app.store && app.store.showroom ? meanVector[0] : position.x,
          app.store && app.store.showroom ? 0 : position.y,
          app.store && app.store.showroom ? meanVector[1] : position.z
        );
        cameraProxy.position.set(
          app.store && app.store.showroom ? meanVector[0] : position.x,
          app.store && app.store.showroom ? 0 : position.y,
          app.store && app.store.showroom ? meanVector[1] : position.z
        );
      }
      if (main.store && !main.store.showroom && main.store.surface) {
        const tapX = window.innerWidth / 2 / window.innerWidth;
        const tapY = window.innerHeight / 2 / window.innerHeight;
        const hitTestResults = window.XR8.XrController.hitTest(tapX, tapY, [
          "FEATURE_POINT",
        ]);

        if (hitTestResults && hitTestResults.length) {
          let {
            position: { x, y, z },
          } = hitTestResults[0];
          main.hitPosition.position.set(x, y, z);
          //let hit = RaycasterHelper.get3DPointFromTap(tapX, tapY, [main.store.surface], false)
          //if (hit) {
          //main.hitPosition.position.copy(hit)
          //}
        }
      }
    },
    onCanvasSizeChange: ({ canvasWidth, canvasHeight }) => {
      let { renderer } = scene3;
      renderer.setSize(canvasWidth, canvasHeight);
    },
    onRender: () => {
      const { scene, renderer, camera } = scene3;
      renderer.clearDepth();
      renderer.render(scene, camera);
    },
    xrScene: () => {
      return scene3;
    },
  };
};
