import React from "react";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import cartActions from "../../redux/actions/cart";
import String from "../../string_en";
import { STORAGE_KEY } from "../../constants";
import { Button, Divider, Drawer, List, Skeleton } from "antd";
import { sumBy, cloneDeep } from "lodash";

class ShoppingCartDrawer extends React.Component {
  static propTypes = {
    isDrawerOpen: PropTypes.bool,
    closeDrawer: PropTypes.func,
    products: PropTypes.array,
    isProductLoading: PropTypes.bool,
    productInfo: PropTypes.func,
    updateLocalStorageCount: PropTypes.func,
    itemsCount: PropTypes.number,
    isCheckoutLoading: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.onClose = this.onClose.bind(this);
    this.fillProducts = this.fillProducts.bind(this);
    this.getTotalPrice = this.getTotalPrice.bind(this);
    this.removeProduct = this.removeProduct.bind(this);
  }

  onClose() {
    this.props.closeDrawer();
  }

  removeProduct(index) {
    //remove from localstorage
    let localProducts = [];
    const productsDetail = cloneDeep(this.props.products);
    if (localStorage.getItem(STORAGE_KEY)) {
      localProducts = JSON.parse(localStorage.getItem(STORAGE_KEY));
    }
    if (index > -1) {
      localProducts.splice(index, 1);
      productsDetail.splice(index, 1);
    }
    localStorage.setItem(STORAGE_KEY, JSON.stringify(localProducts));
    this.localStorageUpdated();

    //remove from redux store
    this.props.productInfo(productsDetail);
  }

  localStorageUpdated() {
    const items = localStorage.getItem(STORAGE_KEY);
    if (items !== null) {
      this.props.updateLocalStorageCount(JSON.parse(items).length);
    }
  }

  fillProducts() {
    const items = this.props.products.map((product, index) => {
      return (
        <List.Item
          key={index}
          extra={
            <img width={100} alt="logo" src={product.productObject.images[0]} />
          }
        >
          <List.Item.Meta
            title={product.productObject.name}
            description={product.productObject.description}
          />
          <h3>
            Price: {(product.unitAmount / 100.0).toFixed(2)}{" "}
            {product.currency.toUpperCase()}
          </h3>
          <Button
            type="dashed"
            size="small"
            onClick={() => this.removeProduct(index)}
          >
            Remove
          </Button>
        </List.Item>
      );
    });
    return (
      <List itemLayout="vertical" size="small">
        {items}
      </List>
    );
  }

  getTotalPrice() {
    const total = sumBy(this.props.products, function (product) {
      return product.unitAmount;
    });
    return (total / 100).toFixed(2);
  }

  render() {
    return (
      <div className="hud-element">
        <Drawer
          title={String.SHOPPING_CART}
          placement="right"
          closable
          onClose={this.onClose}
          visible={this.props.isDrawerOpen}
          width={window.innerWidth > 500 ? 500: window.innerWidth}
          zIndex={999999999999999999}
          getContainer={false}
        >
          <p>
            {String.SUBTOTAL}(
            {this.props.products ? this.props.products.length : 0}) :{" "}
            {this.getTotalPrice()}
          </p>
          <Button
            type="primary"
            block
            loading={this.props.isProductLoading}
            disabled
            onClick={() => {
              window.open(
                this.props.ecommConfig.domain +
                  this.props.ecommConfig.checkoutPath +
                  "#/checkout",
                "_self"
              );
            }}
          >
            {String.CHECKOUT}
          </Button>
          <Divider />
          {this.props.itemsCount === 0 ? <h3>{String.EMPTY}</h3> : null}
          {!this.props.isProductLoading && this.props.products ? (
            this.fillProducts()
          ) : (
            <Skeleton avatar paragraph={{ rows: 4 }} />
          )}
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isDrawerOpen: state.cart.isDrawerOpen,
  products: state.cart.products,
  isProductLoading: state.cart.isProductLoading,
  itemsCount: state.cart.itemsCount,
  ecommConfig: ownProps.ecommConfig,
});

const mapDispatchToProps = (dispatch) => ({
  closeDrawer: () => dispatch(cartActions.closeDrawer()),
  productInfo: (products) => dispatch(cartActions.productInfo(products)),
  updateLocalStorageCount: (count) =>
    dispatch(cartActions.updateLocalStorageCount(count)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCartDrawer);
