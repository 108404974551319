import { InteractionManager } from "../core";

class RaycasterHelper {
  init = () => {
    const { scene, camera } = window.bitreelModule.xrScene();
    this.scene = scene;
    this.camera = camera;
    this.rayCaster = new window.THREE.Raycaster();
    this.tapPosition = new window.THREE.Vector2();
    this.tapPos3D = new window.THREE.Vector3();
    this.get3DPointFromTap = this.get3DPointFromTap.bind(this);
    this.getObjectsFromTap = this.getObjectsFromTap.bind(this);
    this.debugMode = true;
  };

  get3DPointFromTap(tapX, tapY, object) {
    this.tapPos3D.set(
      (tapX / window.innerWidth) * 2 - 1,
      -((tapY / window.innerHeight) * 2 - 1),
      0.5
    ); // z = 0.5 important!

    // unproject is the reverse of project.. it takes 2D coords and turns them into 3D
    this.tapPos3D.unproject(this.camera);

    this.rayCaster.set(
      this.camera.position,
      this.tapPos3D.sub(this.camera.position).normalize()
    );

    var intersects = this.rayCaster.intersectObjects(object, false);
    if (intersects[0]) {
      // not working right now
      // if(this.debugMode){
      //   var points = []
      //   points.push( new window.THREE.Vector3(this.camera.position.x,this.camera.position.y,this.camera.position.z ) )
      //   points.push( new window.THREE.Vector3( intersects[0].x , intersects[0].y , intersects[0].z ) )
      //   var linegeometry = new window.THREE.BufferGeometry().setFromPoints( points )
      //   var linematerial = new window.THREE.LineBasicMaterial( { opacity: 1, transparent: true, color: new window.THREE.Color("#FF0000") } )
      //   var line = new window.THREE.Line( linegeometry, linematerial )
      //   this.scene.add(line)
      // }
      return {
        x: intersects[0].point.x,
        y: intersects[0].point.y,
        z: intersects[0].point.z,
      };
    } else {
      return false;
    }
  }

  getObjectsFromTap(tapX, tapY, objects, recursiveBool) {
    this.tapPos3D.set(
      (tapX / window.innerWidth) * 2 - 1,
      -(tapY / window.innerHeight) * 2 + 1,
      0.5
    ); // z = 0.5 important!
    this.tapPos3D.unproject(this.camera);
    this.rayCaster.set(
      this.camera.position,
      this.tapPos3D.sub(this.camera.position).normalize()
    );
    return this.rayCaster.intersectObjects(objects, recursiveBool);
  }
}

export default new RaycasterHelper();
