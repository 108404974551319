import { createText } from "../utils/threeText";
import Tween from "../utils/Tween";
import store from "../redux";
import UIActions from "../redux/actions/UI";
import { debugMessage } from "../utils/utils";
import cryptoRandomString from "crypto-random-string";

export default class SmartObject {
  constructor(productID, hashID, productData, smartStore) {
    this.productID = productID;
    this.hashID = hashID; // generate unique hashID for the object
    this.productData = productData;
    this.store = smartStore;
    this.collisionMesh = null;
    this.collisionMeshOutline = null;
    this.productViewCollision = null;
    this.productViewOutline = null;
    this.displayText = null;
    this.update = this.update.bind(this);
    this.umbraScene = null;

    this.app = smartStore.app;

    this.worldText = null;
    this.rotateRing = new window.THREE.Group();
    this.lockerPosition = new window.THREE.Vector3();

    this.movePivot = new window.THREE.Object3D();
    this.activeStateIndex = 0;

    this.productRotation = 0;
    this.gltfLoader = smartStore.gltfLoader;
    this.shadowModel = null;
  }

  findCollisionMeshForItemByName = (collisionScene, itemData) => {
    let found = null;
    collisionScene.traverse((obj) => {
      if (
        obj.userData &&
        obj.userData.name &&
        obj.userData.name.indexOf(itemData.shortName + "_collision") !== -1
      ) {
        found = obj;
      }
    });
    return found;
  };

  // TODO: Maybe we need to write different init function for showroom view and product view?
  init = async () => {
    return new Promise(async (resolve, reject) => {
      const { scene, camera, cameraProxy } = window.bitreelModule.xrScene();
      this.scene = scene;
      this.camera = camera;
      this.cameraProxy = cameraProxy;

      this.scene.add(this.movePivot);
      this.collisionMesh = this.findCollisionMeshForItemByName(
        this.store.collisionScene,
        this.productData
      );

      var geoEdges = new window.THREE.EdgesGeometry(
        this.collisionMesh.geometry
      );
      this.collisionMeshOutline = new window.THREE.LineSegments(
        geoEdges,
        new window.THREE.LineBasicMaterial({ color: "#32CD32" })
      );
      this.store.portalContent.add(this.collisionMeshOutline);

      let productCollisionMesh = this.findCollisionMeshForItemByName(
        this.store.productCollisionScene,
        this.productData
      ).clone();
      let geo = productCollisionMesh.geometry.clone();
      let heightOffset = geo.boundingBox.getSize().y / 2 + 0.1;
      let worldPos = this.collisionMesh.geometry.boundingBox.getCenter();

      productCollisionMesh.geometry = geo;
      productCollisionMesh.geometry.translate(
        -worldPos.x,
        -worldPos.y + heightOffset,
        -worldPos.z
      );

      // rotate geo the initial store rotation amount to match..
      productCollisionMesh.geometry.rotateY(-Math.PI / 2);

      var geoEdges = new window.THREE.EdgesGeometry(
        productCollisionMesh.geometry
      );
      var productViewOutline = new window.THREE.LineSegments(
        geoEdges,
        new window.THREE.LineBasicMaterial({ color: "#FFFFFF" })
      );

      this.productViewCollision = productCollisionMesh;
      this.productViewOutline = productViewOutline;
      this.productViewOutline.visible = false;
      this.displayText = this.productData.name + "";

      this.locked = true;

      this.itemPosition = new window.THREE.Vector3();

      this.textWorldPosition = new window.THREE.Vector3();

      this.cameraPosition = new window.THREE.Vector3();
      this.hideBoundingBox();

      const {
        font,
        opacity,
        textColor,
        bgColor,
        lineColor,
      } = this.productData.text;
      const offset = {
        position: new window.THREE.Vector3(
          worldPos.x,
          worldPos.y + 1,
          worldPos.z
        ),
        rotation: new window.THREE.Vector3(0, 0, 0),
        scale: new window.THREE.Vector3(1, 1, 1),
      };
      this.minDistance = this.productData.text.minDistance; // This is for text fade in/out distance
      this.audioDistance = this.productData.sound.triggerDistance; // This is for audio trigger distance

      this.worldText = createText(
        this.store.threeFonts[font],
        this.displayText,
        opacity,
        textColor,
        bgColor,
        lineColor,
        offset
      );
      this.worldText.renderOrder = 1;
      this.store.portalContent.add(this.worldText);
      this.worldText.visible = false;
      this.showRoomPostion = this.productData.offset.position;
      this.showRoomRotation = this.productData.offset.rotation;
      this.showRoomScale = this.productData.offset.scale;

      this.productScale = this.productData.offset.scale;

      if (store.getState().UI.mode === "showroom") {
        this.collisionMesh.traverse((obj) => {
          obj.userData = {
            ...obj.userData,
            smartObject: this,
          };
        });
      }

      resolve(this);
    });
  };

  hideBoundingBox = () => {
    this.collisionMeshOutline.visible = false;
  };
  showBoundingBox = () => {
    this.collisionMeshOutline.visible = true;
  };

  showProduct3DUI = () => {
    Tween.fadeAnimation(this.rotateRing, 1, 0, 1000);
    this.locker.visible = true;
  };

  hideProduct3DUI = () => {
    Tween.fadeAnimation(this.rotateRing, 0, 1, 1000);
    this.locker.visible = false;
  };

  revertToPreviousState = () => {
    this.setObjectState(this.activeStateIndex);
  };

  setObjectState = async (index, preview = false) => {
    if (preview || this.activeStateIndex !== index) {
      this.activeStateIndex = index;
      const {
        umbra,
        umbra: { productStates },
      } = this.productData;
      let nextState = productStates[index];

      store.dispatch(UIActions.updateItemLoading(true));
      const nextUmbraScene = await this.app.umbraManager.getUmbraScene(
        nextState.key,
        umbra.umbraProjectId,
        nextState.modelId,
        this.productData.shaderType
      );
      if (this.productData.shaderType !== "defaultumbra") {
        nextUmbraScene.material.envMap = this.app.envTexture;
      }
      nextUmbraScene.position.copy(this.umbraScene.position);
      nextUmbraScene.rotation.copy(this.umbraScene.rotation);
      nextUmbraScene.add(this.productViewCollision);
      nextUmbraScene.add(this.productViewOutline);
      store.dispatch(UIActions.updateItemLoading(false));
      if (nextState.hasOwnProperty("shadowModel")) {
        this.gltfLoader.load(nextState.shadowModel, async (shadowModel) => {
          this.umbraScene.remove(this.shadowModel);
          this.shadowModel = shadowModel.scene;
          nextUmbraScene.add(this.shadowModel);
        });
      }
      this.movePivot.add(nextUmbraScene);
      setTimeout(() => {
        this.movePivot.remove(this.umbraScene);
        this.umbraScene = nextUmbraScene;
      }, 200);
    }
  };

  addToProductView = async () => {
    const {
      productData: { umbra },
    } = this;
    if (!this.umbraScene) {
      store.dispatch(UIActions.updateItemLoading(true));
      this.umbraScene = await this.app.umbraManager.getUmbraScene(
        umbra.productStates[0].key,
        umbra.umbraProjectId,
        umbra.productStates[0].modelId,
        this.productData.shaderType
      );
      if (this.productData.shaderType !== "defaultumbra") {
        this.umbraScene.material.envMap = this.app.envTexture;
      }
      this.umbraScene.add(this.productViewCollision);
      this.umbraScene.add(this.productViewOutline);
      this.umbraScene.add(this.generateProductRing());
      store.dispatch(UIActions.updateItemLoading(false));
    }
    this.umbraScene.traverse((obj) => {
      obj.userData = {
        root: this.umbraScene,
        smartObject: this,
      };
    });

    if (this.shadowModel == null) {
      this.gltfLoader.load(
        this.productData.defaultShadow,
        async (shadowModel) => {
          debugMessage("shadow model added  " + this.productData.defaultShadow);
          this.shadowModel = shadowModel.scene;
          this.umbraScene.add(this.shadowModel);
        }
      );
    } else {
      this.umbraScene.add(this.shadowModel);
    }
    if (store.getState().UI.mode === "product") {
      // check if it's still in product view
      this.movePivot.add(this.umbraScene);
      //store.dispatch(UIActions.updateHideBanner(true));
      this.store.startProductViewPlacement(this);
      this.unlockModel();
    }
  };

  generateProductRing = () => {
    var edge = this.collisionMesh.geometry.boundingSphere.radius * 1.5;
    var innerRadius = edge - 0.1;
    var outerRadius = edge + 0.1;
    var geoMaterial = new window.THREE.MeshBasicMaterial({
      color: "skyblue",
      side: window.THREE.DoubleSide,
    });
    var arrowGeo = new window.THREE.Geometry();
    var v1 = new window.THREE.Vector3(
      (innerRadius + outerRadius) / 2 - 0.2,
      0,
      0
    );
    var v2 = new window.THREE.Vector3(
      (innerRadius + outerRadius) / 2 + 0.2,
      0,
      0
    );
    var v3 = new window.THREE.Vector3((innerRadius + outerRadius) / 2, -0.2, 0);

    arrowGeo.vertices.push(v1);
    arrowGeo.vertices.push(v2);
    arrowGeo.vertices.push(v3);

    arrowGeo.faces.push(new window.THREE.Face3(0, 1, 2));
    arrowGeo.computeFaceNormals();

    var arrow = new window.THREE.Mesh(arrowGeo, geoMaterial);
    var ringGeo = new window.THREE.RingGeometry(
      innerRadius,
      outerRadius,
      64,
      8,
      0,
      Math.PI / 2
    );

    var rotateRing_p1 = new window.THREE.Mesh(ringGeo, geoMaterial);
    rotateRing_p1.rotation.x = -Math.PI / 2;
    rotateRing_p1.scale.set(0.4, 0.4, 0.4);
    rotateRing_p1.add(arrow);

    var rotateRing_p2 = new window.THREE.Mesh();
    var rotateRing_p3 = new window.THREE.Mesh();
    rotateRing_p2 = rotateRing_p1.clone();
    rotateRing_p3 = rotateRing_p1.clone();

    rotateRing_p2.rotation.z = (2 * Math.PI) / 3;
    rotateRing_p3.rotation.z = (2 * Math.PI * 2) / 3;

    this.rotateRing.add(rotateRing_p1);
    this.rotateRing.add(rotateRing_p2);
    this.rotateRing.add(rotateRing_p3);
    this.rotateRing.position.set(0, 0.1, 0);
    return this.rotateRing;
  };

  lockModel = () => {
    this.locked = true;
    this.yHeight = this.movePivot.position.y;
    this.lockMesh.visible = this.locked;
    this.unlockMesh.visible = !this.locked;
    store.dispatch(UIActions.updateForceHide(false));
  };

  unlockModel = () => {
    this.locked = false;
    this.lockMesh.visible = this.locked;
    this.unlockMesh.visible = !this.locked;
    store.dispatch(UIActions.updateForceHide(true));
  };

  setSurfaceHeight = (position) => {
    this.movePivot.position.set(position.x, position.y, position.z);
    this.yHeight = position.y;
  };

  removeFromProductView = () => {
    // need to find a good way to remove smart objects
    this.scene.remove(this.movePivot);
  };

  copy = () => {
    let newHash = cryptoRandomString({ length: 10 });
    const newObject = new SmartObject(
      this.productID,
      newHash,
      this.productData,
      this.store
    );
    newObject.init();
    return newObject;
  };

  beginMoveProduct = () => {
    this.scene.attach(this.umbraScene);
    this.movePivot.position.copy(this.umbraScene.position);
    this.movePivot.attach(this.umbraScene);
  };

  moveProduct = (velX, velY) => {
    this.movePivot.lookAt(
      new window.THREE.Vector3(
        this.store.cameraProxy.position.x,
        this.movePivot.position.y,
        this.store.cameraProxy.position.z
      )
    );

    this.movePivot.translateX(velX * (this.deltaTime / 1000));
    this.movePivot.translateZ(velY * (this.deltaTime / 1000));
  };

  setProductScale(scale) {
    this.movePivot.scale.set(scale, scale, scale);
  }

  addLockModel = () => {
    if (!this.locker) {
      this.locker = this.store.lockModel.clone();
      this.store.productView.objectsRaycastable.push(this.locker);
      this.movePivot.add(this.locker);
      this.locker.position.set(0, 1, 0);
      this.locker.traverse((obj) => {
        if (obj.userData.name) {
          if (obj.userData.name === "lock") {
            debugMessage("lock mesh found" + obj);
            this.lockMesh = obj;
            this.lockMesh.visible = false;
          } else if (obj.userData.name === "unlock") {
            debugMessage("unlock mesh found" + obj);
            this.unlockMesh = obj;
          }
        }
        obj.userData = {
          rootObject: this,
        };
      });
    }
  };

  updateObjectTransform = (lastGazeHitPos) => {
    if (this.umbraScene) {
      if (!this.locked) {
        this.itemPosition.copy(lastGazeHitPos);
        this.movePivot.position.set(
          this.itemPosition.x,
          this.itemPosition.y,
          this.itemPosition.z
        );
      }

      this.umbraScene.rotation.set(0, this.productRotation, 0);
    }
  };

  update(dtSeconds, time, lastGazeHitPos) {
    this.deltaTime = dtSeconds;
    if (this.camera) {
      this.cameraPosition = this.camera.position;
      if (this.store.showroom) {
        this.worldText.getWorldPosition(this.textWorldPosition);
        this.worldText.lookAt(
          this.camera.position.x,
          0,
          this.camera.position.z
        ); //This method does not support objects having non-uniformly-scaled parent(s)
        const distance = this.cameraPosition.distanceTo(this.textWorldPosition);
        if (this.worldText) {
          this.worldText.scale.set(
            0.5 + 0.2 * distance,
            0.5 + 0.2 * distance,
            0.5 + 0.1 * distance
          ); // dymamically change the scale of the text based on the distance
          // do the distance check / fading here
          if (distance <= this.minDistance) {
            if (this.worldText.material.opacity == 0) {
              Tween.fadeAnimation(this.worldText, 1, 0, 1000);
            }
          }
          if (distance > this.minDistance) {
            if (this.worldText.material.opacity == 1) {
              Tween.fadeAnimation(this.worldText, 0, 1, 1000);
            }
          }
        }
      } else {
        if (this.locker) {
          this.locker.getWorldPosition(this.lockerPosition);
          this.locker.lookAt(
            this.camera.position.x,
            this.lockerPosition.y,
            this.camera.position.z
          ); //This method does not support objects having non-uniformly-scaled parent(s)
        }
        this.updateObjectTransform(lastGazeHitPos);
      }
    }
  }
}
