import { map } from "jquery";
import { keys, forEach, find } from "lodash";

const defaultState = {
  storeConfigLoading: false,
  productsConfigLoading: false,
  storeConfig: null,
  storeReady: false,
  switchingMode: false,
  configLoadTime: null,
  platform: null,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case "UPDATE_STORE_READY": {
      return {
        ...state,
        storeReady: action.storeReady,
      };
    }
    case "UPDATE_PLATFORM": {
      return {
        ...state,
        platform: action.platform,
      };
    }
    case "UPDATE_CONFIG_LOAD_TIME": {
      return {
        ...state,
        configLoadTime: action.configLoadTime,
      };
    }

    case "UPDATE_STORE_CONFIG_LOADING": {
      return {
        ...state,
        storeConfigLoading: action.loading,
      };
    }
    case "UPDATE_PRODUCT_CONFIG_LOADING": {
      return {
        ...state,
        productsConfigLoading: action.loading,
      };
    }
    case "CREATE_STORE_CONFIG": {
      return {
        ...state,
        storeConfig: action.storeConfig,
      };
    }
    case "UPDATE_PRODUCTS_FROM_STRIPE": {
      forEach(state.storeConfig.sections, (section) => {
        forEach(keys(section.items), (item_id) => {
          let product = find(action.products, function (product) {
            return product.id === item_id;
          });
          section.items[item_id].price = product.unitAmount / 100;
          section.items[item_id].name = product.productObject.name;
          section.items[item_id].description =
            product.productObject.description;
          //section.items[item_id].imageUrl = product.productObject.images[0]
        });
      });
      return state;
    }
    case "UPDATE_STORE_CONFIG": {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case "UPDATE_SWITCHING_MODE": {
      return {
        ...state,
        switchingMode: action.switchingMode,
      };
    }
    default:
      return state;
  }
}
