import React, { Component } from "react";
import "./ScreenshotComponent.scss";
import connect from "react-redux/es/connect/connect";
import UIActions from "../../redux/actions/UI";
import store from "../../redux/reducers/store";

class ScreenshotComponent extends Component {
  state = {
    previewImage: null,
  };
  constructor(props) {
    super(props);
  }
  takeSnapshot = () => {
    const { scene, renderer, camera } = window.bitreelModule.xrScene();
    renderer.clearDepth();
    renderer.render(scene, camera); // render once before take the snapshot
    var canvas = document.getElementById("canvaselement");
    var screenshot = canvas.toDataURL(1.0);
    this.setState({
      previewImage: screenshot,
    });
  };

  saveSnapshot = () => {
    console.log("save");
    this.setState({
      previewImage: null,
    });
  };

  render() {
    return (
      <div>
        <div
          className="screenshot-button hud-element"
          onClick={() => this.takeSnapshot()}
        >
          {this.props.icon}
        </div>
        {this.state.previewImage && (
          <div className="preview" onClick={() => this.saveSnapshot()}>
            <img src={this.state.previewImage} />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScreenshotComponent);
