export function createText(
  font,
  content = "default",
  opacity = 1,
  textcolor,
  bgcolor,
  linecolor,
  offset
) {
  var geometry = new window.THREE.TextGeometry(content, {
    font: font,
    size: 0.1,
    height: 0.01,
    curveSegments: 4,
  });

  geometry.center();
  var textmaterial = new window.THREE.MeshStandardMaterial({
    side: window.THREE.DoubleSide,
    opacity: opacity,
    transparent: true,
    color: new window.THREE.Color(textcolor),
    depthTest: false,
  });
  var text = new window.THREE.Mesh(geometry, textmaterial);

  if (bgcolor) {
    var bgmaterial = new window.THREE.MeshStandardMaterial({
      side: window.THREE.DoubleSide,
      opacity: opacity,
      transparent: true,
      color: new window.THREE.Color(bgcolor),
      depthTest: false,
    });
    const bg = new window.THREE.Mesh(
      new window.THREE.PlaneGeometry(content.length / 14 + 0.2, 0.2, 1),
      bgmaterial
    );
    bg.position.set(0, 0, -0.1);
    text.add(bg);
  }

  if (linecolor) {
    var points = [];
    points.push(
      new window.THREE.Vector3(-content.length / 28 - 0.1, -0.1, -0.1)
    );
    points.push(
      new window.THREE.Vector3(content.length / 28 + 0.1, -0.1, -0.1)
    );
    var linegeometry = new window.THREE.BufferGeometry().setFromPoints(points);
    var linematerial = new window.THREE.LineBasicMaterial({
      opacity: opacity,
      transparent: true,
      color: new window.THREE.Color(linecolor),
      depthTest: false,
    });
    var line = new window.THREE.Line(linegeometry, linematerial);
    text.add(line);
  }
  text.position.set(offset.position.x, offset.position.y, offset.position.z);
  text.rotation.set(offset.rotation.x, offset.rotation.y, offset.rotation.z);
  text.scale.set(offset.scale.x, offset.scale.y, offset.scale.z);

  return text;
}
