import React, { Component } from "react";
import { Provider } from "react-redux";
import store from "./redux";
import storeActions from "./redux/actions/store";
import App from "./App";
import ReactGA from "react-ga";
import ScriptTag from "react-script-tag";

class AppMain extends Component {
  state = {
    loadingDeps: true,
    depsCountRemaining: 5,
    threeLoaded: false,
  };

  constructor(props) {
    super(props);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const storename = urlParams.get("store");

    store.dispatch(
      storeActions.fetchStoreConfig(
        process.env.NODE_ENV === "production" ? this.props.storeName : storename
      )
    );

    //Initialize Google Analytics
      this.initializeGA()
  }

  initializeGA = () => {
      const unsubscribe = store.subscribe(() => {
          if (store.getState().store.storeConfig != null) {
              process.env.REACT_APP_DEV_BUILD
                  ? ReactGA.initialize(  [
                      {
                          trackingId: store.getState().store.storeConfig.gaDev.trackingId,
                          gaOptions: {
                              name: 'bitreelTracker',
                          }
                      }], {debug: true, useExistingGa: false})
                  : ReactGA.initialize(                {
                      trackingId: store.getState().store.storeConfig.ga.trackingId,
                      gaOptions: {
                          name: 'bitreelTracker',
                      }
                  }, {useExistingGa: false});
              // //dev
              // //ReactGA.initialize("UA-182119984-1", {debug: true});
              ReactGA.pageview(window.location.pathname + window.location.search, ['bitreelTracker']);
              ReactGA.timing({
                  category: "config file",
                  variable: "load",
                  value: store.getState().store.configLoadTime, // in milliseconds
                  label: "showroom config file",
              }, ['bitreelTracker']);
              unsubscribe();
          }
      });
  }

  renderApp = () => {
    return (
      <Provider store={store}>
        {/* these are downloaded in index.js. for them to work in shadowroot, they have to be present in both */}
        {/* <link
          rel="stylesheet"
          href={"https://bitreel-viewer-scripts.s3.amazonaws.com/bitreel-styles" + (process.env.REACT_APP_DEV_BUILD ? "-dev" : "") + ".css"}
        /> */}
        <link
          rel="stylesheet"
          href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
        />
        <App
          storeName={this.props.storeName}
          showroomName={this.props.showroomName}
          customElement={this.props.customElement}
        />
      </Provider>
    );
  };

  registerDepLoaded = () => {
    this.setState(
      {
        depsCountRemaining: this.state.depsCountRemaining - 1,
      },
      () => {
        if (!this.state.depsCountRemaining) {
          this.setState({
            loadingDeps: false,
          });
        }
      }
    );
  };

  render() {
    if (this.state.loadingDeps) {
      return (
        <>
          <ScriptTag
            onLoad={() => {
              this.registerDepLoaded();
              this.setState({
                threeLoaded: true,
              });
            }}
            type="text/javascript"
            src="https://bitreel-viewer-scripts.s3.amazonaws.com/three.r120.min.js"
          />
          {this.state.threeLoaded && (
            <>
              <ScriptTag
                onLoad={this.registerDepLoaded}
                type="text/javascript"
                src="https://bitreel-viewer-scripts.s3.amazonaws.com/GLTFLoader.js"
              />
              <ScriptTag
                onLoad={this.registerDepLoaded}
                type="text/javascript"
                src="https://bitreel-viewer-scripts.s3.amazonaws.com/umbrajs-three.js"
              />
              <ScriptTag
                onLoad={this.registerDepLoaded}
                type="text/javascript"
                src="https://bitreel-viewer-scripts.s3.amazonaws.com/inflate.js"
              />
              <ScriptTag
                onLoad={this.registerDepLoaded}
                type="text/javascript"
                src="https://bitreel-viewer-scripts.s3.amazonaws.com/lottie-player.js"
              />
            </>
          )}
        </>
      );
    } else {
      return this.renderApp();
    }
  }
}

export default AppMain;
