export function setCameraRange(camera, model) {
  const bounds = model.getBounds();
  const x = bounds.max.x - bounds.min.x;
  const y = bounds.max.y - bounds.min.y;
  const z = bounds.max.z - bounds.min.z;
  const diagonal = Math.sqrt(x * x + y * y + z * z);

  camera.near = Math.max(0.01, Math.min(1.0, diagonal / 1e4));
  camera.far = Math.max(100, diagonal * 4);
  camera.updateProjectionMatrix();
}
