export const cloneMaterials = (object3D) => {
  object3D.traverse((o) => {
    if (o.isMesh && o.geometry !== undefined) {
      if (o.material) {
        o.material = o.material.clone();
      }
    }
  });
};

export const makeObjectTransparent = (object3D) => {
  object3D.traverse((o) => {
    if (o.isMesh && o.geometry !== undefined) {
      o.visible = false;
    }
  });
};
