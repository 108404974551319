import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import UIActions from "../../redux/actions/UI";
import "./TipImage.scss";

class TipImage extends Component {
  state = {};

  componentDidMount() {
    this.setTipstimer();
  }
  setTipstimer = () => {
    this.setState({
      mounted: true,
      currentTip: this.props.animatedTip,
    });
    setTimeout(() => {
      if (this.state.currentTip === this.props.animatedTip) {
        this.setState({
          mounted: false,
        });
        setTimeout(() => {
          this.props.updateAnimatedTip(null);
        }, 500);
      } else {
        this.setTipstimer();
      }
    }, this.props.animatedTip.duration - 500);
  };

  hideTip = () => {
    this.setState({
      mounted: false,
    });
  };

  render() {
    return (
      <div
        className={`tipimage ${this.state.mounted ? "mounted" : ""}`}
        onClick={this.hideTip}
      >
        <lottie-player
          src={this.props.animatedTip.lottieUrl}
          initialSegment={[100, 200]}
          mode="bounce"
          background="transparent"
          speed="1"
          loop
          autoplay
        />
        <h2>{this.props.text}</h2>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  animatedTip: state.UI.animatedTip,
  lottieUrl: state.UI.animatedTip.lottieUrl,
  text: state.UI.animatedTip.text,
});

const mapDispatchToProps = (dispatch) => ({
  updateAnimatedTip: (tip) => dispatch(UIActions.updateAnimatedTip(tip)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TipImage);
