import React, { Component } from "react";
import "./GazeIndicator.scss";

class GazeIndicator extends Component {
  state = {};
  render() {
    return <div className="gaze-indicator">TAP ITEM FOR OPTIONS</div>;
  }
}

export default GazeIndicator;
