import React, { Component } from "react";
import "./OverlayMessage.scss";

class OverlayMessage extends Component {
  state = {};

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        mounted: true,
      });
    }, 50);
  }

  render() {
    return (
      <div
        className={`overylay-message-container ${
          this.state.mounted ? "mounted" : ""
        }`}
      >
        <div className="overylay-message-container__inner">
          <p>{this.props.body}</p>
        </div>
      </div>
    );
  }
}

export default OverlayMessage;
