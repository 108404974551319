import storeActions from "../redux/actions/store";
import store from "../redux";
//determine ipad pro
function isIpadOS() {
  store.dispatch(storeActions.updatePlatform("mobile"));
  return (
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2 &&
    /MacIntel/.test(navigator.platform)
  );
}

export function debugMessage(message, value) {
  if (process.env.NODE_ENV !== "test") {
    if (value) {
      console.warn(message, value);
    } else {
      console.warn(message);
    }
  }
}

//Determine OS
export function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    store.dispatch(storeActions.updatePlatform("mobile"));
    return "Windows Phone";
  } else if (/android/i.test(userAgent)) {
    store.dispatch(storeActions.updatePlatform("mobile"));
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  else if (
    (/iPad|iPhone|iPod/.test(userAgent) || isIpadOS()) &&
    !window.MSStream
  ) {
    store.dispatch(storeActions.updatePlatform("mobile"));
    return "iOS";
  } else {
    store.dispatch(storeActions.updatePlatform("desktop"));
    return "unknown";
  }
}
