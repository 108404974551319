import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import UIActions from "../../redux/actions/UI";
import "./Instruction.scss";
import SoundManager from "../../core/SoundManager";
import { debugMessage } from "../../utils/utils";

class Instruction extends Component {
  state = {};
  constructor(props) {
    super(props);
    this.props.updateInstruction(this.props.instruction);
  }
  renderInstruction = (instruction) => {
    switch (instruction) {
      case "audio":
        return (
          <div className="audio-instruction">
            <lottie-player
              src="https://assets2.lottiefiles.com/packages/lf20_62ahen0v.json"
              initialSegment={[100, 200]}
              mode="bounce"
              background="transparent"
              speed="1"
              loop
              autoplay
            />
            <h2 className="instruction-text">
              Unmute your device for the best experience
            </h2>
          </div>
        );
      case "joystickMove":
        SoundManager.playOnce("joystick");
        return (
          <div className="joystick-instruction">
            <div className="joystick-animation-inner">
              <div className="joystick-animation-offset">
                <div className="joystickMove-animation" />
              </div>
            </div>
            <h1>Drag vertical to move</h1>
          </div>
        );
      case "joystickRotate":
        return (
          <div className="joystick-instruction">
            <div className="joystick-animation-inner">
              <div className="joystick-animation-offset">
                <div className="joystickRotate-animation" />
              </div>
            </div>
            <h2>Drag horizontal to rotate</h2>
          </div>
        );
      case "setting":
        return (
          <div className="setting-instruction">
            <h2 className="instruction-text">Checkout here</h2>
            {this.props.icon}
          </div>
        );
      case "end":
        setTimeout(() => {
          this.skip();
        }, 1000);
        SoundManager.stopOnce();
        return !this.props.instructionSkip ? (
          <div className="end">
            <h1> Tap to continue</h1>
          </div>
        ) : (
          <></>
        );
      default:
        debugMessage("unknown instruction type: " + instruction);
        return;
    }
  };

  onClick = () => {
    debugMessage("instructions click");
    if (!this.props.instructionSkip) {
      this.props.updateInstructionSkip(true);
    }
    this.props.updateCurrentInstruction(this.props.currentIndex + 1);
  };

  componentDidMount() {
    this.props.updateCurrentInstruction(0);
  }

  skip = () => {
    this.props.updateInstructionSkip(true);
    this.props.updateCurrentInstruction(
      this.props.instruction.content.length - 1
    );
  };

  render() {
    return (
      <>
        <div
          className={`instruction-container ${
            this.state.hideBg ? "hideBg" : ""
          }`}
          onClick={() => this.onClick()}
          // onTouchMove={this.onTouchMove}
        >
          {this.renderInstruction(this.props.instructionState)}
        </div>
        <div className="skip-button" onClick={() => this.skip()}>
          <h1> Skip </h1>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  instructionState: state.UI.instructionState,
  instructionSkip: state.UI.instructionSkip,
  currentIndex: state.UI.currentIndex,
  icon: ownProps.icon,
  instruction: ownProps.instruction,
});

const mapDispatchToProps = (dispatch) => ({
  updateInstructionState: (state) =>
    dispatch(UIActions.updateInstructionState(state)),
  updateInstructionSkip: (bool) =>
    dispatch(UIActions.updateInstructionSkip(bool)),
  updateCurrentInstruction: (index) =>
    dispatch(UIActions.updateCurrentInstruction(index)),
  updateInstruction: (instruction) =>
    dispatch(UIActions.updateInstruction(instruction)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Instruction);
