export default (startupFunction, main) => {
  let scene3;

  const scene = new window.THREE.Scene();
  scene.add(main.hitPosition);

  const camera = new window.THREE.PerspectiveCamera(
    70,
    window.innerWidth / window.innerHeight,
    0.01,
    200
  );
  let cameraChild = new window.THREE.Object3D();
  camera.add(cameraChild);

  let container = document.createElement("div");
  document.body.appendChild(container);

  let renderer = new window.THREE.WebGLRenderer({
    antialias: true,
    alpha: true,
  });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);

  // TODO: FIX THIS
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const storename = urlParams.get("store");

  if (storename !== "boconcept") {
    renderer.toneMapping = window.THREE.ACESFilmicToneMapping;
  }

  renderer.xr.enabled = true;

  let cameraProxy = new window.THREE.Object3D();
  scene.add(cameraProxy);

  container.appendChild(renderer.domElement);
  renderer.domElement.setAttribute("id", "domElement");
  let gl = renderer.getContext();

  scene3 = { scene, camera, renderer, gl, cameraProxy, cameraChild, main };

  const onWindowResize = function () {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    renderer.setSize(window.innerWidth, window.innerHeight);
  };

  window.addEventListener("resize", onWindowResize, false);

  // I think this is how camera is magically getting bound ?
  let controller = renderer.xr.getController(0);

  scene.add(controller);

  renderer.xr.setFramebufferScaleFactor(0.4);

  const renderWebXRThree = (frame, pose) => {
    if (pose) {
      cameraProxy.position.set(
        pose.transform.position.x,
        pose.transform.position.y,
        pose.transform.position.z
      );
      var vector = new window.THREE.Vector3(
        pose.transform.orientation.x,
        pose.transform.orientation.y,
        pose.transform.orientation.z
      );
      cameraProxy.setRotationFromAxisAngle(
        vector,
        pose.transform.orientation.w
      );
      cameraProxy.updateMatrix();
      cameraProxy.updateMatrixWorld();
    }

    renderer.render(scene, camera);
    // if(store.getState().UI.takeScreenShot){
    //   //  html2canvas(document.body).then(function(canvas) {
    //   //   renderer.render(scene, camera);
    //   //   var myImage = canvas.toDataURL("image/png");
    //   //   console.log(myImage);
    //   // });
    //   gl.bindFramebuffer(gl.FRAMEBUFFER, null);

    //   // Clear the framebuffer
    //   gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

    //   // Set the viewport to the whole canvas
    //   gl.viewport(0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight);

    //   var canvas = renderer.domElement
    //   var screenshot = canvas.toDataURL(1.0);
    //   console.log(screenshot);
    // }
  };

  window.bitreelModule = {
    xrScene: () => {
      return scene3;
    },
    renderWebXRThree,
  };

  startupFunction();

  return {
    xrScene: () => {
      return scene3;
    },
    renderWebXRThree,
  };
};
