import React, { Component } from "react";
import "./Joystick.scss";
import nipplejs from "nipplejs";
import connect from "react-redux/es/connect/connect";

class ShowroomJoystick extends Component {
  state = {
    storeRotation: 0,
    rendered: false,
  };

  componentDidMount() {
    let zone;
    if (this.props.bitreelMain.customElement) {
      zone = this.props.bitreelMain.customElement.shadowRoot.querySelector(
        "#joystick-mount"
      );
    } else {
      zone = document.querySelector("#joystick-mount");
    }

    this.nippleManager = nipplejs.create({
      zone,
      mode: "static",
      size: 300,
      maxNumberOfNipples: 1,
      dynamicPage: true,
      position: {
        left: "57%",
        top: 0,
      },
    });
    this.nippleManager.on("move", (e, moveData) => {
      this.props.bitreelMain.store.joystickTranslateStore(moveData);
    });
    this.nippleManager.on("end", () => {
      this.props.bitreelMain.store.joystickReleaseStore();
      this.setState({
        active: false,
      });
    });
    this.nippleManager.on("start", () => {
      this.props.bitreelMain.store.joystickControlStart();
      this.setState({
        active: true,
      });
    });
  }

  render() {
    return (
      <div style={{ position: "fixed", bottom: "30%" }}>
        <div
          className={`hud-element joystick ${
            this.state.active ? "active" : ""
          } ${this.props.selectedSmartObject ? "selected" : ""} `}
          onTouchStart={this.touchStart}
          onTouchEnd={this.touchEnd}
        >
          <div id="joystick-mount" className="joystick__inner" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  selectedSmartObject: state.UI.selectedSmartObject,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ShowroomJoystick);
