import React from "react";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import "./SharePopup.scss";
import UIActions from "../../redux/actions/UI";
import { Col, Modal, Row } from "antd";
import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
} from "react-share";
import enString from "../../string_en";

class SharePopup extends React.Component {
  static propTypes = {
    sharePopup: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.props.showSharePopup(!this.props.sharePopup);
  }

  render() {
    return (
      <Modal
        className={"modal"}
        visible={this.props.sharePopup}
        onCancel={this.onClick}
        getContainer={false}
        footer={null}
      >
        <h4>{enString.SHARE_MODAL}</h4>
        <Row gutter={16}>
          <Col className="gutter-row" span={2}>
            <FacebookShareButton
              url={window.top.location.href}
              quote={enString.FACEBOOK_QUOTE}
              hastag={enString.FACEBOOK_HASHTAG}
            >
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
          </Col>
          <Col className="gutter-row" span={2}>
            <LinkedinShareButton
              url={window.top.location.href}
              title={enString.LINKEDIN_TITLE}
              summary={enString.LINKEDIN_SUMMARY}
              source={enString.LINKEDIN_SOURCE}
            >
              <LinkedinIcon size={32} round={true} />
            </LinkedinShareButton>
          </Col>
          <Col className="gutter-row" span={2}>
            <TwitterShareButton
              url={window.top.location.href}
              title={enString.TWITTER_TITLE}
              hashtags={enString.TWITTER_HASHTAGS}
              related={enString.TWITTER_RELATED}
            >
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
          </Col>
        </Row>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  sharePopup: state.UI.sharePopup,
});

const mapDispatchToProps = (dispatch) => ({
  showSharePopup: (show) => dispatch(UIActions.showSharePopup(show)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SharePopup);
