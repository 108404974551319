import { makeObjectTransparent } from "../../utils/threeUtils";
import SoundManager from "../SoundManager";
import store from "../../redux";
import storeActions from "../../redux/actions/store";
import { debugMessage } from "../../utils/utils";

export function initStore() {
  // TODO: Turn all the awaits that can be to a promise.all
  // to speed loading further.

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const storename = urlParams.get("store");

  this.platform = store.getState().store.platform;
  switch (this.platform) {
    case "mobile":
      this.desktop = false;
      return this.initMobile();
    case "desktop":
      this.desktop = true;
      return this.initDesktop();
    default:
      this.desktop = false;
      debugMessage("unknown platform");
      break;
  }
}
export function initMobile() {
  return new Promise((resolve, reject) => {
    const { umbraManager } = this.app;
    const {
      scene,
      cameraChild,
      camera,
      composer,
      outlinePass,
      cameraProxy,
      renderer,
    } = window.bitreelModule.xrScene();
    this.cameraProxy = cameraProxy;
    this.renderer = renderer;
    this.composer = composer;
    this.outlinePass = outlinePass;

    this.camera = camera;
    this.cameraChild = cameraChild;
    this.scene = scene;

    const {
      meta: { defaultFloor },
    } = this.storeConfig;
    const floorData = this.helpers.findFloorByName(defaultFloor);
    this.gltfLoader = new window.THREE.GLTFLoader();
    this.gltfLoader.load(this.storeConfig.interaction_model.lock, (model) => {
      this.lockModel = model.scene;
      this.lockModel.traverse(function (node) {
        if (node.material) {
          node.material.opacity = 0.5;
          node.material.transparent = true;
        }
      });
    });
    debugMessage("loading floor data...");
    if (floorData) {
      debugMessage("floorData found...");
      this.gltfLoader.load(
        `https://bitreel-arshowrooms.s3.amazonaws.com/${this.app.storeName}/collisionScenes/${this.app.showroomName}.gltf`,
        async (model) => {
          this.collisionScene = model.scene;
          this.productCollisionScene = model.scene.clone(true);
          makeObjectTransparent(this.productCollisionScene);
          this.scene.add(this.productCollisionScene);

          makeObjectTransparent(this.collisionScene);

          this.initPortalSpace(
            this.scene,
            floorData,
            this.storeConfig.meta.addPortal
          );
          this.loadFonts(this.storeConfig).then((result) => {
            store.dispatch(
              storeActions.loadProductsForSection(defaultFloor, floorData, this)
            );
          });

          SoundManager.init();
          SoundManager.loadFloorSound(floorData);
          this.offset = floorData.portal.door;
          debugMessage("loading default umbra scene..");
          const defaultUmbraShell = await umbraManager.getUmbraScene(
            floorData.umbraKey,
            floorData.umbraProjectId,
            floorData.umbraModelId,
            floorData.shaderType
          );
          debugMessage("scene loaded..");
          if (floorData.shaderType !== "defaultumbra") {
            defaultUmbraShell.material.envMap = this.app.envTexture;
          }
          defaultUmbraShell.scale.set(-1, 1, 1);
          defaultUmbraShell.position.set(0, 0, 0);
          this.portalContent.add(defaultUmbraShell);
          this.portalContent.add(this.collisionScene);
          const { position, rotation, scale } = floorData.offset;
          this.portalContent.rotation.set(rotation.x, rotation.y, rotation.z);
          this.portalContent.position.set(position.x, position.y, -5.5); // position.z
          this.portalContent.scale.set(scale.x, scale.y, scale.z);
          this.wholePortal.add(this.portalContent);
          this.userCollisionMesh = new window.THREE.Mesh(
            new window.THREE.BoxBufferGeometry(0.3, 0.3, 0.3),
            new window.THREE.MeshBasicMaterial({
              color: 0xffff00,
              transparent: true,
              opacity: 0,
            })
          );

          // get the collision mesh of the whole store
          this.collisionScene.traverse((obj) => {
            if (
              obj.userData &&
              obj.userData.name &&
              obj.userData.name.indexOf("exterior_collision") !== -1
            ) {
              obj.geometry = new window.THREE.Geometry().fromBufferGeometry(
                obj.geometry
              );
              this.storeCollisionMesh = obj;
            }
          });

          this.scene.add(this.wholePortal);
          this.scene.add(this.rotationPivotOne);
          this.scene.add(this.rotationPivotTwo);
          this.rotationPivotOne.position.copy(this.camera.position);
          this.activeRotationPivot = "One";
          this.scene.add(this.userCollisionMesh);
          this.showroom = true;

          this.startSession();
          store.dispatch(storeActions.isStoreReady(true));
          if (this.app.xrMode) {
            this.wholePortal.position.x =
              this.wholePortal.position.x + this.cameraProxy.position.x;
            this.wholePortal.position.z =
              this.wholePortal.position.z + this.cameraProxy.position.z;
            this.wholePortal.rotation.x =
              this.wholePortal.rotation.x + this.cameraProxy.rotation.x;
            this.wholePortal.rotation.z =
              this.wholePortal.rotation.z + this.cameraProxy.rotation.z;
          } else {
            window.XR8.XrController.recenter();
          }

          resolve(true);
        }
      );
    } else {
      console.warn("Store loading failed, floor data not found!!");
    }
  });
}
export function initDesktop() {
  return new Promise((resolve, reject) => {
    const { umbraManager } = this.app;
    const {
      scene,
      cameraChild,
      camera,
      composer,
      outlinePass,
      cameraProxy,
      renderer,
      controls,
    } = window.bitreelModule.xrScene();
    this.cameraProxy = cameraProxy;
    this.renderer = renderer;
    this.composer = composer;
    this.outlinePass = outlinePass;
    this.controls = controls;
    this.camera = camera;
    this.cameraChild = cameraChild;
    this.scene = scene;
    this.desktop = store.getState().store.platform === "desktop";
    const {
      meta: { defaultFloor },
    } = this.storeConfig;
    const floorData = this.helpers.findFloorByName(defaultFloor);
    this.gltfLoader = new window.THREE.GLTFLoader();
    debugMessage("loading floor data...");
    if (floorData) {
      this.gltfLoader.load(
        `https://bitreel-arshowrooms.s3.amazonaws.com/${this.app.storeName}/collisionScenes/${this.app.showroomName}.gltf`,
        async (model) => {
          this.collisionScene = model.scene;
          this.productCollisionScene = model.scene.clone(true);
          makeObjectTransparent(this.productCollisionScene);
          this.scene.add(this.productCollisionScene);

          makeObjectTransparent(this.collisionScene);

          this.initPortalSpace(
            this.scene,
            floorData,
            this.storeConfig.meta.addPortal
          );
          this.loadFonts(this.storeConfig).then((result) => {
            store.dispatch(
              storeActions.loadProductsForSection(defaultFloor, floorData, this)
            );
          });
          SoundManager.init();
          SoundManager.loadFloorSound(floorData);
          this.offset = floorData.portal.door;
          debugMessage("getting default umbra scene...");
          const defaultUmbraShell = await umbraManager.getUmbraScene(
            floorData.umbraKey,
            floorData.umbraProjectId,
            floorData.umbraModelId,
            floorData.shaderType
          );

          if (floorData.shaderType !== "defaultumbra") {
            defaultUmbraShell.material.envMap = this.app.envTexture;
          }
          defaultUmbraShell.scale.set(-1, 1, 1);
          defaultUmbraShell.position.set(0, 0, 0);
          this.portalContent.add(defaultUmbraShell);
          this.portalContent.add(this.collisionScene);
          const { position, rotation, scale } = floorData.offset;
          this.portalContent.rotation.set(rotation.x, rotation.y, rotation.z);
          this.portalContent.position.set(position.x, position.y, -5.5); // position.z
          this.portalContent.scale.set(scale.x, scale.y, scale.z);
          this.wholePortal.add(this.portalContent);
          this.scene.add(this.wholePortal);
          this.userCollisionMesh = new window.THREE.Mesh(
            new window.THREE.BoxBufferGeometry(0.3, 0.3, 0.3),
            new window.THREE.MeshBasicMaterial({
              color: 0xffff00,
              transparent: true,
              opacity: 0,
            })
          );

          // get the collision mesh of the whole store
          this.collisionScene.traverse((obj) => {
            if (
              obj.userData &&
              obj.userData.name &&
              obj.userData.name.indexOf("exterior_collision") !== -1
            ) {
              obj.geometry = new window.THREE.Geometry().fromBufferGeometry(
                obj.geometry
              );
              this.storeCollisionMesh = obj;
            }
          });

          this.startSession();
          store.dispatch(storeActions.isStoreReady(true));
          resolve(true);
        }
      );
    } else {
      console.warn("Store loading failed, floor data not found!!");
    }
  });
}
export function startSession() {
  let currentState, currentSkip;
  const unsubscribe = store.subscribe(() => {
    let previousState = currentState;
    let previousSkip = currentSkip;
    currentState = store.getState().UI.instructionState;
    currentSkip = store.getState().UI.instructionSkip;
    if (currentState !== previousState || currentSkip !== previousSkip) {
      if (currentState === "start") {
      } else if (currentState === "end" && currentSkip) {
        SoundManager.stopOnce();

        setTimeout(() => {
          SoundManager.playLoop("background");
          SoundManager.playOnce("intro");
        }, 500);
        // InteractionManager.disableHammer()
        unsubscribe();
      }
    }
  });
}

export function initPortalSpace(scene, floorData, addPortal) {
  // Enable shadows in the rednerer.
  this.wholePortal.name = "wholePortal";
  this.portalContent.name = "portalContent";
  const size = floorData.portal.size;
  const color = floorData.portal.doorColor;
  const door = floorData.portal.door;
  const lightPos = floorData.portal.lightPos;
  this.surface = new window.THREE.Mesh(
    new window.THREE.PlaneGeometry(2000, 2000, 1, 1),
    new window.THREE.MeshBasicMaterial({
      color: 0xffff00,
      transparent: true,
      opacity: 0.0,
      side: window.THREE.DoubleSide,
    })
  );
  this.surface.rotateX(-Math.PI / 2);
  this.surface.position.set(0, 0, 0);
  scene.add(this.surface);
  scene.add(new window.THREE.AmbientLight(0x404040, 5));

  if (addPortal) {
    // portal hide material
    const portalmaterial = new window.THREE.MeshBasicMaterial();
    portalmaterial.side = window.THREE.DoubleSide;
    portalmaterial.colorWrite = false;
    //doorway material
    const material = new window.THREE.MeshPhysicalMaterial();
    material.side = window.THREE.DoubleSide;
    material.metalness = 0;
    material.color = new window.THREE.Color(color);
    //Portal doorway
    this.portalEntrance.name = "portalEntrance";
    this.portalDoor.name = "portalDoor";
    this.portalDoor.add(
      this.createThreeBoxMesh(
        new window.THREE.Vector3(door.thickness, door.height, door.thickness),
        new window.THREE.Vector3(
          -(door.width + door.thickness) / 2,
          door.height / 2,
          0
        ),
        material
      )
    ); //left
    this.portalDoor.add(
      this.createThreeBoxMesh(
        new window.THREE.Vector3(door.thickness, door.height, door.thickness),
        new window.THREE.Vector3(
          (door.width + door.thickness) / 2,
          door.height / 2,
          0
        ),
        material
      )
    ); //right
    this.portalDoor.add(
      this.createThreeBoxMesh(
        new window.THREE.Vector3(
          door.width + door.thickness * 2,
          door.thickness,
          door.thickness
        ),
        new window.THREE.Vector3(0, door.height + door.thickness / 2, 0),
        material
      )
    ); //up
    this.portalEntrance.add(this.portalDoor);
    //Portal hider wall
    this.hiderWall.name = "hiderWall";
    this.hiderWall.add(
      this.createThreeBoxMesh(
        new window.THREE.Vector3(
          door.width + 2 * door.thickness,
          size - door.thickness - door.height,
          1
        ),
        new window.THREE.Vector3(
          0,
          (size + door.height + door.thickness) / 2,
          0
        ),
        portalmaterial
      )
    ); // front up wall
    this.hiderWall.add(
      this.createThreeBoxMesh(
        new window.THREE.Vector3(
          (size - door.width - door.thickness * 2) / 2,
          size,
          1
        ),
        new window.THREE.Vector3(
          -(
            (size - door.width - door.thickness * 2) / 4 +
            door.width / 2 +
            door.thickness
          ),
          size / 2,
          0
        ),
        portalmaterial
      )
    ); //front left wall
    this.hiderWall.add(
      this.createThreeBoxMesh(
        new window.THREE.Vector3(
          (size - door.width - door.thickness * 2) / 2,
          size,
          1
        ),
        new window.THREE.Vector3(
          (size - door.width - door.thickness * 2) / 4 +
            door.width / 2 +
            door.thickness,
          size / 2,
          0
        ),
        portalmaterial
      )
    ); //front right wall
    this.hiderWall.add(
      this.createThreeBoxMesh(
        new window.THREE.Vector3(size, size, 1),
        new window.THREE.Vector3(0, size / 2, size),
        portalmaterial
      )
    ); //back wall
    this.hiderWall.add(
      this.createThreeBoxMesh(
        new window.THREE.Vector3(size, 1, size + door.thickness),
        new window.THREE.Vector3(0, size, size / 2),
        portalmaterial
      )
    ); //up wall
    this.hiderWall.add(
      this.createThreeBoxMesh(
        new window.THREE.Vector3(size, 1, size - door.thickness),
        new window.THREE.Vector3(0, -door.thickness / 2, size / 2),
        portalmaterial
      )
    ); //down wall
    this.hiderWall.add(
      this.createThreeBoxMesh(
        new window.THREE.Vector3(1, size, size),
        new window.THREE.Vector3(-size / 2, size / 2, size / 2),
        portalmaterial
      )
    ); //left wall
    this.hiderWall.add(
      this.createThreeBoxMesh(
        new window.THREE.Vector3(1, size, size),
        new window.THREE.Vector3(size / 2, size / 2, size / 2),
        portalmaterial
      )
    ); //right wall
    this.portalEntrance.add(this.hiderWall);

    this.portalWall = this.createThreeBoxMesh(
      new window.THREE.Vector3(door.width, door.height, door.thickness),
      new window.THREE.Vector3(door.width, door.height / 2, door.thickness / 2),
      portalmaterial
    );
    this.portalWall.name = "portalWall";
    this.portalEntrance.add(this.portalWall);
    this.wholePortal.add(this.portalEntrance);
  }
}
