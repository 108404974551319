import React, { Component } from "react";
import storeActions from "./redux/actions/store";
import UIActions from "./redux/actions/UI";
import "./main.scss";
import {
  ShoppingCartDrawer,
  ShoppingCartButton,
  ViewerMenu,
  AudioButton,
  HelpButton,
} from "./UI/components";
import connect from "react-redux/es/connect/connect";
import MobileMode from "./mode/MobileMode";
import DesktopMode from "./mode/DesktopMode";

import { getMobileOperatingSystem } from "./utils/utils";
import ShareButton from "./UI/components/Menu/ShareButton";

class App extends Component {
  state = {
    bitreelMain: null,
  };

  renderViewerMenuComponents = () => {
    let storeConfig = this.props.storeInfo;
    if (storeConfig.ecomm.enabled) {
      return (
        <>
          <ViewerMenu
            icon={<i className="fa fa-bars" />}
            mountPoint={
              this.props.customElement
                ? this.props.customElement.mountPoint
                : document.body
            }
          >
            <ShoppingCartButton
              ecommConfig={storeConfig.ecomm}
              icon={
                <i
                  className="fa fa-shopping-cart"
                  style={{ fontSize: "40px" }}
                />
              }
            />
            <AudioButton
              audioOnIcon={
                <i
                  className="fa fa-volume-up"
                  style={{ fontSize: "40px", transform: "translateX(3px)" }}
                />
              }
              audioOffIcon={
                <i
                  className="fa fa-volume-off"
                  style={{ fontSize: "40px", transform: "translateX(3px)" }}
                />
              }
            />
            <ShareButton key="shareButton" />
            <HelpButton icon={<i className="fa fa-question" />} />
          </ViewerMenu>

          <ShoppingCartDrawer ecommConfig={storeConfig.ecomm} />
        </>
      );
    }
    return null;
  };

  render() {
    return (
      <>
        {getMobileOperatingSystem() === "unknown" ? (
          <DesktopMode
            renderViewerMenuComponents={this.renderViewerMenuComponents}
            storeName={this.props.storeName}
            customElement={this.props.customElement}
          />
        ) : (
          <MobileMode
            renderViewerMenuComponents={this.renderViewerMenuComponents}
            storeName={this.props.storeName}
            customElement={this.props.customElement}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  loading:
    state.store.storeConfigLoading ||
    state.store.productsConfigLoading ||
    !state.store.storeReady,
  storeInfo: state.store.storeConfig,
  enteredAR: state.UI.enteredAR,
  mode: state.UI.mode,
  selectedSmartObject: state.UI.selectedSmartObject,
  objectGazed: state.UI.objectGazed,
  animatedTip: state.UI.animatedTip,
  addingProduct: state.UI.addingProduct,
  itemLoading: state.UI.itemLoading,
  overlayMessage: state.UI.overlayMessage,
  instructionState: state.UI.instructionState,
  instructionSkip: state.UI.instructionSkip,
});

const mapDispatchToProps = (dispatch) => ({
  fetchStoreConfig: (storename) =>
    dispatch(storeActions.fetchStoreConfig(storename)),
  updateStoreConfig: (key, value) =>
    dispatch(storeActions.updateStoreConfig(key, value)),
  updateEnteredAR: (enter) => dispatch(UIActions.updateEnteredAR(enter)),
  updateLoading: (isLoading) => dispatch(UIActions.updateLoading(isLoading)),
  updateObjectGazed: (smartObject) =>
    dispatch(UIActions.updateObjectGazed(smartObject)),
  updateOverlayMessage: (message) =>
    dispatch(UIActions.updateOverlaymessage(message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
