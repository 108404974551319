import { ShowroomLoader } from "./loaders";
import BitreelThreeModule from "./8thwallModule";
import RaycasterHelper from "./utils/RaycasterHelper";
import WebXRModule from "./WebXRModule";
import DesktopModule from "./DesktopModule";
import store from "./redux";
import { UmbraManager, InteractionManager } from "./core";
import { debugMessage } from "./utils/utils";

window.bitreelModule = null;
export default class BitreelMain {
  constructor(reactApp, config, storeName, showroomName, customElement) {
    this.storeName = storeName;
    this.showroomName = showroomName;
    this.customElement = customElement;

    this.config = config;
    this.reactApp = reactApp;
    this.update = this.update.bind(this);
    this.onXRFrame = this.onXRFrame.bind(this);
    this.xrRefSpace = null;
    this.xrSession = null;
    this.xrMode = false;

    // this is for the constant raycast for the center of the screen cursor world hit position
    this.hitPosition = new window.THREE.Object3D();

    this.hitTestSource = null;
    this.hitTestSourceRequested = false;

    this.previousFrameTime = 0;

    const initWihoutWebXR = () => {
      window.XRExtras
        ? this.onxrloaded()
        : window.addEventListener("xrextrasloaded", this.onxrloaded);
    };
    this.platform = store.getState().store.platform;
    switch (this.platform) {
      case "mobile":
        this.desktopMode = false;
        if (window.navigator.xr) {
          window.navigator.xr
            .isSessionSupported("immersive-ar")
            .then((supported) => {
              if (supported) {
                this.xrMode = true;
                WebXRModule(this.initBitreelViewerMain, this);
                this.startXRSession();
              } else {
                initWihoutWebXR();
              }
            })
            .catch((e) => {
              console.warn("immersive-ar not supported:", e);
            });
        } else {
          console.warn("init without webxr");
          initWihoutWebXR();
        }
        break;
      case "desktop":
        this.desktopMode = true;
        // document.getElementById("camerafeed").remove();
        DesktopModule(this.initBitreelViewerMain, this).then((res) => {
          this.renderer = res.renderer;
          this.scene = res.scene;
          this.camera = res.camera;
          this.controls = res.controls;
          window.requestAnimationFrame(this.update);
        });

        break;
      default:
        console.warn("unknown platform");
        break;
    }
  }

  startXRSession = () => {
    return window.navigator.xr
      .requestSession("immersive-ar", {
        requiredFeatures: ["hit-test", "dom-overlay"],
        domOverlay: {
          root:
            process.env.NODE_ENV === "production"
              ? this.customElement.mountPoint
              : document.body,
        },
      })
      .then((session) => {
        this.onXRSessionStarted(session);
      })
      .catch((e) => {
        console.warn("xr features not suppoted:", e);
      });
  };

  onXRSessionStarted = async (session) => {
    console.warn("XRSession requested", session);
    let { renderer } = window.bitreelModule.xrScene();
    this.renderer = renderer;
    renderer.xr.setReferenceSpaceType("local");
    renderer.xr.setSession(session);

    session.addEventListener("end", this.onXRSessionEnded);
    renderer.setAnimationLoop(this.onXRFrame);
    console.warn("XRSession created", session);
  };

  onXRFrame(t, frame) {
    if (frame) {
      var referenceSpace = this.renderer.xr.getReferenceSpace();
      var session = this.renderer.xr.getSession();
      let pose = frame.getViewerPose(referenceSpace);

      if (this.hitTestSourceRequested === false) {
        console.warn("into request");
        session.requestReferenceSpace("viewer").then((referenceSpace) => {
          session
            .requestHitTestSource({ space: referenceSpace })
            .then((source) => {
              console.warn("hit test source", source);
              this.hitTestSource = source;
            })
            .catch((e) => {
              console.warn("hit test source failed:", e);
            });
        });

        this.hitTestSourceRequested = true;
      }

      if (this.hitTestSource) {
        var hitTestResults = frame.getHitTestResults(this.hitTestSource);
        if (hitTestResults.length) {
          var hit = hitTestResults[0];
          this.hitPosition.matrix.fromArray(
            hit.getPose(referenceSpace).transform.matrix
          );
          this.hitPosition.position.setFromMatrixPosition(
            this.hitPosition.matrix
          );
        }
      }

      window.bitreelModule.renderWebXRThree(frame, pose);
      this.update(t, pose);
    }
  }

  onXRSessionEnded = (e) => {
    this.hitTestSourceRequested = false;
    this.hitTestSource = null;
    console.warn("WebXR Session ended !", e);
  };

  onxrloaded = (retry) => {
    if (!retry) {
      this.bitreelModule = BitreelThreeModule(this.initBitreelViewerMain, this); // the function passed gets called after 8thwall is initialized.
      window.bitreelModule = this.bitreelModule;
    }

    try {
      window.XR8.addCameraPipelineModules([
        window.XR8.GlTextureRenderer.pipelineModule(), // Draws the camera feed.
        this.bitreelModule, // CUSTOM: Creates the scene, renderer and camera and Umbra connections
        window.XR8.XrController.pipelineModule(), // Enables SLAM tracking.
        window.XRExtras.AlmostThere.pipelineModule(), // Detects unsupported browsers and gives hints.
        window.XRExtras.FullWindowCanvas.pipelineModule(), // Modifies the canvas to fill the window.
        window.XRExtras.Loading.pipelineModule(), // Manages the loading screen on startup.
        window.XRExtras.RuntimeError.pipelineModule(), // Shows an error image on runtime error.
      ]);

      window.XR8.run({ canvas: document.querySelector("#canvaselement") });
      debugMessage("XR8 run");
    } catch (error) {
      setTimeout(() => {
        this.onxrloaded(true);
      }, 100);
    }
    window.requestAnimationFrame(this.update);
  };

  initBitreelViewerMain = async () => {
    let { renderer } = window.bitreelModule.xrScene();
    this.renderer = renderer;

    const config = this.config;

    const { type } = config;
    debugMessage("initBitreelViewerMain");
    switch (type) {
      case "showroom":
        // TODO: Turn all the awaits that can be to a promise.all
        // to speed loading further.

        this.umbraManager = new UmbraManager(config, this);
        debugMessage("initSreaming");
        await this.umbraManager.init();
        debugMessage("streamingInit");

        const Loader = new ShowroomLoader({
          storeConfig: config,
          app: this,
        });

        // TODO: get a loader service/class for this and other textures
        var loader = new window.THREE.CubeTextureLoader();
        loader.setPath(
          "https://bitreel-arshowrooms.s3.amazonaws.com/hellmanchang/envMaps/hellman_chang_demo/"
        );
        loader.load(
          ["px.png", "nx.png", "py.png", "ny.png", "pz.png", "nz.png"],
          async (texture) => {
            this.envTexture = texture;

            setTimeout(async () => {
              this.store = await Loader.initStore();

              setTimeout(async () => {
                InteractionManager.init(this, ".showroom-hud", "showroom", [
                  this.store.enableShowroomGestures,
                ]);
                RaycasterHelper.init();
              }, 1500);
            }, 400);
          }
        );
        debugMessage("envCubeTexturesLoaded");
        break;
      default:
        console.warn("unknown bitreel config type");
        break;
    }
  };

  update(t, pose) {
    // global update stuff up here only!
    if (this.previousFrameTime) {
      const deltaTime = t - this.previousFrameTime;
      if (this.umbraManager) {
        this.umbraManager.update(deltaTime, t);
      }

      if (this.store) {
        this.store.update(deltaTime, t, pose);
      }
      this.previousFrameTime = t;
    } else {
      this.previousFrameTime = t;
    }

    if (!this.xrMode) {
      window.requestAnimationFrame(this.update);
    }
    if (this.desktopMode) {
      this.renderer.render(this.scene, this.camera);
      this.controls.update();
    }
  }
}
