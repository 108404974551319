import _, { forEach } from "lodash";
import { InteractionManager } from "..";
import Tween from "../../utils/Tween";
import SoundManager from "../SoundManager";
import constructorExtras from "./constructor";
import {
  initPortalSpace,
  initStore,
  startSession,
  initDesktop,
  initMobile,
} from "./initPortalSpace";
import helpers from "./helpers";
import joystickFxns from "./joystick";
import store from "../../redux";
import UIActions from "../../redux/actions/UI";
import storeActions from "../../redux/actions/store";
import { debugMessage } from "../../utils/utils";

export default class SmartStore {
  constructor({ storeConfig, app }) {
    this.storeConfig = storeConfig;
    this.app = app;
    this.update = this.update.bind(this);
    this.checkShowroomObjectGaze = this.checkShowroomObjectGaze.bind(this);

    // Done to keep mountains of THREE boilerplate out of here.
    this.constructorExtras = constructorExtras.bind(this);
    this.initPortalSpace = initPortalSpace.bind(this);
    this.initStore = initStore.bind(this);
    this.startSession = startSession.bind(this);
    this.initDesktop = initDesktop.bind(this);
    this.initMobile = initMobile.bind(this);
    this.constructorExtras();

    this.helpers = {};
    _.forEach(helpers, (fx, fxName) => (this.helpers[fxName] = fx.bind(this)));

    this.joystick = {};
    _.forEach(joystickFxns, (fx, fxName) => (this[fxName] = fx.bind(this)));
    debugMessage("ShowroomCreated");
  }

  init = () => {
    // Done to keep mountains of THREE boilerplate out of here.
    return this.initStore();
  };

  loadFonts = (config) => {
    let numFonts = _.keys(config.three.fonts).length;
    const fontloader = new window.THREE.FontLoader();

    return new Promise(async (resolve, reject) => {
      _.forEach(config.three.fonts, (fontUrl, fontName) => {
        fontloader.load(fontUrl, (result) => {
          this.threeFonts[fontName] = result;
          numFonts--;
          if (!numFonts) {
            resolve(true);
          }
        });
      });
    });
  };

  enableShowroomGestures = () => {
    let {
      UI: { selectedSmartObject },
    } = store.getState();

    InteractionManager.onGesture("singletap", (event) => {
      debugMessage("showroom single tap");
      const tapPosition = new window.THREE.Vector2();
      const rayCaster = new window.THREE.Raycaster();
      tapPosition.x = (event.center.x / window.innerWidth) * 2 - 1;
      tapPosition.y = -(event.center.y / window.innerHeight) * 2 + 1;
      rayCaster.setFromCamera(tapPosition, this.camera);
      var intersects = rayCaster.intersectObjects(
        this.floorObjectsCollision,
        true
      );
      if (intersects[0]) {
        if (intersects[0].object.userData.smartObject) {
          if (!selectedSmartObject) {
            intersects[0].object.userData.smartObject.hideBoundingBox();
            this.highlightedObject = null;
            this.firstSelectedShowroomProduct = true;
            SoundManager.stopOnce();
            SoundManager.playOnce(
              intersects[0].object.userData.smartObject.productData.shortName
            );
            store.dispatch(
              UIActions.updateSelectedSmartObject({
                productID: intersects[0].object.userData.smartObject.productID,
                hashID: intersects[0].object.userData.smartObject.hashID,
              })
            );
          } else {
            debugMessage("selected object set to null");
            store.dispatch(UIActions.updateSelectedSmartObject(null));
          }
        }
      } else {
        debugMessage("not intersect" + this.helpers.getSelectedObject());
        if (this.helpers.getSelectedObject()) {
          debugMessage("selected object set to null");
          store.dispatch(UIActions.updateSelectedSmartObject(null));
        }
      }
    });
  };

  enableProductGestures = () => {
    InteractionManager.onGesture("singletap", (event) => {
      debugMessage("Product single tap");
      if (this.productView.objectBeingPlaced) {
        this.productView.objectBeingPlaced.lockModel();
        this.deselectProductViewItem();
        store.dispatch(UIActions.updateSelectedSmartObject(null));
        this.productView.objectBeingPlaced = null;
        if (!this.showroom && !this.productView.firstSelected) {
          debugMessage("tap item for option tip");
          const tip = {
            lottieUrl:
              "https://assets6.lottiefiles.com/datafiles/2q6iDbG1jlX9qW5/data.json",
            text: "tap items for more options",
            duration: 3000,
          };
          store.dispatch(UIActions.updateAnimatedTip(tip));
        }
      } else {
        const tapPosition = new window.THREE.Vector2();
        const rayCaster = new window.THREE.Raycaster();
        tapPosition.x = (event.center.x / window.innerWidth) * 2 - 1;
        tapPosition.y = -(event.center.y / window.innerHeight) * 2 + 1;
        rayCaster.setFromCamera(tapPosition, this.camera);
        var intersects = rayCaster.intersectObjects(
          this.productView.objectsRaycastable,
          true
        );
        if (intersects[0]) {
          if (intersects[0].object.userData.smartObject) {
            if (!this.productView.selectedProductViewItem) {
              if (!this.productView.firstSelected) {
                if (!this.app.xrMode) {
                  const tip1 = {
                    lottieUrl:
                      "https://assets7.lottiefiles.com/temp/lf20_YWg42Q.json",
                    text: "Pinch to resize",
                    duration: 3000,
                  };
                  store.dispatch(UIActions.updateAnimatedTip(tip1));
                  setTimeout(() => {
                    const tip2 = {
                      lottieUrl:
                        "https://assets1.lottiefiles.com/datafiles/uegKqn66PxUj7CR/data.json",
                      text: "drag the item to reposition",
                      duration: 3000,
                    };
                    store.dispatch(UIActions.updateAnimatedTip(tip2));
                  }, 3100);
                } else {
                  const tip = {
                    lottieUrl:
                      "https://assets1.lottiefiles.com/datafiles/uegKqn66PxUj7CR/data.json",
                    text: "drag the item to reposition",
                    duration: 3000,
                  };
                  store.dispatch(UIActions.updateAnimatedTip(tip));
                }

                this.productView.firstSelected = true;
              }
              store.dispatch(
                UIActions.updateSelectedSmartObject({
                  productID:
                    intersects[0].object.userData.smartObject.productID,
                  hashID: intersects[0].object.userData.smartObject.hashID,
                })
              );
              store.dispatch(UIActions.updateForceHide(false));
              this.selectProductViewItem(
                intersects[0].object.userData.smartObject
              );
            } else {
              debugMessage("object tapped again, deselect the product");
              this.deselectProductViewItem();
              store.dispatch(UIActions.updateSelectedSmartObject(null));
            }
          } else if (intersects[0].object.userData.rootObject) {
            if (store.getState().UI.selectedSmartObject) {
              intersects[0].object.userData.rootObject.unlockModel();
              this.productView.objectBeingPlaced =
                intersects[0].object.userData.rootObject;
            }
          }
        } else {
          if (this.productView.selectedProductViewItem) {
            debugMessage("tapped somewhere else. deselected the product");
            this.deselectProductViewItem();
            store.dispatch(UIActions.updateSelectedSmartObject(null));
          }
        }
      }
    });

    InteractionManager.onGesture("rotatestart pinchstart", (e) => {
      InteractionManager.adjustRotation -= e.rotation;
      this.gestureState.rotating = false;
      this.gestureState.pinching = false;
      this.gestureState.startingScale = InteractionManager.currentScale;
      this.gestureState.startingRotation = InteractionManager.currentRotation;
    });

    InteractionManager.onGesture("pinchmove rotatemove", (e) => {
      if (this.app.xrMode) {
        InteractionManager.currentRotation =
          (InteractionManager.adjustRotation + e.rotation) % 360;

        this.productView.selectedProductViewItem.productRotation =
          (-InteractionManager.currentRotation / 180) * Math.PI;
        return;
      }

      if (!this.gestureState.rotating && !this.gestureState.pinching) {
        this.gestureState.accumulatedRotation = Math.abs(
          ((InteractionManager.adjustRotation + e.rotation) % 360) -
            this.gestureState.startingRotation
        );
        this.gestureState.accumulatedScale = Math.abs(
          InteractionManager.adjustScale * e.scale -
            this.gestureState.startingScale
        );

        if (this.gestureState.accumulatedRotation > 4) {
          this.gestureState.rotating = true;
        }
        if (this.gestureState.accumulatedScale > 0.15) {
          this.gestureState.pinching = true;
        }
      } else {
        if (this.gestureState.pinching) {
          InteractionManager.currentScale =
            InteractionManager.adjustScale * e.scale;
          InteractionManager.currentDeltaX =
            InteractionManager.adjustDeltaX +
            e.deltaX / InteractionManager.currentScale;
          InteractionManager.currentDeltaY =
            InteractionManager.adjustDeltaY +
            e.deltaY / InteractionManager.currentScale;
        } else if (this.gestureState.rotating) {
          InteractionManager.currentRotation =
            (InteractionManager.adjustRotation + e.rotation) % 360;
        }
      }

      if (this.productView.selectedProductViewItem) {
        this.productView.selectedProductViewItem.productRotation =
          (-InteractionManager.currentRotation / 180) * Math.PI;
        if (!this.app.xrMode) {
          this.productView.selectedProductViewItem.setProductScale(
            InteractionManager.currentScale
          );
        }
      }
    });

    InteractionManager.onGesture("pinchend rotateend", (e) => {
      InteractionManager.adjustRotation = InteractionManager.currentRotation;

      InteractionManager.adjustScale = InteractionManager.currentScale;
      InteractionManager.adjustDeltaX = InteractionManager.currentDeltaX;
      InteractionManager.adjustDeltaY = InteractionManager.currentDeltaY;
    });

    InteractionManager.onGesture("panstart", (event) => {
      if (this.productView.selectedProductViewItem) {
        this.productView.selectedProductViewItem.beginMoveProduct();
      }
    });

    InteractionManager.onGesture("doubletap", (event) => {
      if (this.productView.selectedProductViewItem) {
        if (this.app.xrMode) {
          this.productView.selectedProductViewItem.setSurfaceHeight(
            this.app.hitPosition.position
          );
        } else {
          const tapX = event.center.x / window.innerWidth;
          const tapY = event.center.y / window.innerHeight;
          const hitTestResults = window.XR8.XrController.hitTest(tapX, tapY, [
            "FEATURE_POINT",
          ]);
          if (hitTestResults && hitTestResults.length) {
            let {
              position: { x, y, z },
            } = hitTestResults[0];
            this.productView.selectedProductViewItem.setSurfaceHeight({
              x,
              y,
              z,
            });
          }
        }
      }
    });

    InteractionManager.onGesture("panmove", (event) => {
      if (event.center.x < window.innerWidth - 50) {
        if (this.productView.selectedProductViewItem) {
          this.productView.selectedProductViewItem.moveProduct(
            event.velocityX * this.productView.productPanSpeed,
            event.velocityY * this.productView.productPanSpeed
          );
        }
      }
      if (
        this.app.reactApp.productHud &&
        this.app.reactApp.productHud.sideMenu
      ) {
        this.app.reactApp.productHud.sideMenu.setPanXY(
          event.center.x,
          event.center.y
        );
      }
    });

    InteractionManager.onGesture("panend", (event) => {
      if (
        this.app.reactApp.productHud &&
        this.app.reactApp.productHud.sideMenu
      ) {
        this.app.reactApp.productHud.sideMenu.panEnd();
      }
    });
  };

  addObjectToProductView = async (smartObject) => {
    if (!this.showroom) {
      // check if the object is the first object added to the product view
      SoundManager.stopOnce();
      SoundManager.playOnce(smartObject.productData.shortName);
    }
    if (this.productView.selectedProductViewItem) {
      this.deselectProductViewItem();
    }
    const object = smartObject.copy();
    // assign a new hashID
    await object.addLockModel();
    await object.addToProductView();
    store.dispatch(UIActions.updateForceHide(true));
    this.productView.objectsAdded[object.productID][object.hashID] = object;
    await this.productView.objectsRaycastable.push(object.productViewCollision);
    await this.selectProductViewItem(object);
  };

  deleteObject = (selectedSmartObject) => {
    store.dispatch(UIActions.updateSelectedSmartObject(null));
    this.deselectProductViewItem();
    const index = this.productView.objectsRaycastable.indexOf(
      this.productView.objectsAdded[selectedSmartObject.productID][
        selectedSmartObject.hashID
      ].productViewCollision
    );
    if (index > -1) {
      this.productView.objectsRaycastable.splice(index, 1);
    }
    this.productView.objectsAdded[selectedSmartObject.productID][
      selectedSmartObject.hashID
    ].removeFromProductView();
    delete this.productView.objectsAdded[selectedSmartObject.productID][
      selectedSmartObject.hashID
    ];
  };

  // this is for product view gestures. If there is an object being placed,
  // the next tap places it.
  startProductViewPlacement = (smartObject) => {
    this.productView.objectBeingPlaced = smartObject;
  };

  selectProductViewItem = (smartObject) => {
    this.productView.selectedProductViewItem = smartObject;
    this.productView.selectedProductViewItem.showProduct3DUI();
  };

  deselectProductViewItem = () => {
    this.productView.selectedProductViewItem
      ? this.productView.selectedProductViewItem.hideProduct3DUI()
      : debugMessage("no item selected");
    this.productView.selectedProductViewItem = null;
  };

  enableProductView = (selectedSmartObject) => {
    store.dispatch(storeActions.updateSwitchingMode(true));
    store.dispatch(UIActions.updateMode("product"));
    InteractionManager.disableHammer();
    const object = this.floorSmartObjects[selectedSmartObject.productID][
      selectedSmartObject.hashID
    ];
    this.lastShowroomPosition = {
      x: this.cameraProxy.position.x,
      z: this.cameraProxy.position.z,
    };
    forEach(this.floorSmartObjects, (object) => {
      forEach(object, (item) => {
        item.worldText.visible = false;
      });
    });
    //store.dispatch(UIActions.updateSelectedSmartObject(null));
    setTimeout(async () => {
      this.wholePortal.visible = false;
      this.addObjectToProductView(object);
      debugMessage("tap to place tip");
      this.showroom = false;
      const tip = {
        lottieUrl:
          "https://assets6.lottiefiles.com/datafiles/2q6iDbG1jlX9qW5/data.json",
        text: "tap anywhere to place",
        duration: 3000,
      };
      store.dispatch(UIActions.updateAnimatedTip(tip));
      //debugger
      //await store.dispatch(UIActions.updateMode("product"));
      InteractionManager.init(this.app, ".product-hud", "product", [
        this.enableProductGestures,
      ]);
      //this.selectProductViewItem(object);
      //store.dispatch(UIActions.updateSelectedSmartObject(object.productID));
      store.dispatch(storeActions.updateSwitchingMode(false));
    }, 1000);
  };

  enableShowroomView = async () => {
    store.dispatch(storeActions.updateSwitchingMode(true));
    store.dispatch(UIActions.updateMode("showroom"));
    store.dispatch(UIActions.updateForceHide(false));
    InteractionManager.disableHammer();
    store.dispatch(UIActions.updateSelectedSmartObject(null));
    if (this.app.xrMode) {
      let xDifference =
        this.cameraProxy.position.x - this.lastShowroomPosition.x;
      let zDifference =
        this.cameraProxy.position.z - this.lastShowroomPosition.z;
      this.wholePortal.position.x = this.wholePortal.position.x + xDifference;
      this.wholePortal.position.z = this.wholePortal.position.z + zDifference;
    } else {
      window.XR8.XrController.recenter();
    }
    this.wholePortal.visible = true;
    SoundManager.stopOnce();
    await store.dispatch(UIActions.updateAddingProduct(false));

    this[`rotationPivot${this.activeRotationPivot}`].attach(this.wholePortal);
    this.showroom = true;
    forEach(this.productView.objectsAdded, (object) => {
      forEach(object, (item) => {
        item.hideProduct3DUI();
        item.removeFromProductView();
      });
      this.productView.objectsAdded[object] = {};
    });

    this.productView.objectsRaycastable = [];

    //await store.dispatch(UIActions.updateMode("showroom"));

    InteractionManager.init(this.app, ".showroom-hud", "showroom", [
      this.enableShowroomGestures,
    ]);
    store.dispatch(storeActions.updateSwitchingMode(false));
  };

  createThreeBoxMesh = (scale, position, material) => {
    const box = new window.THREE.Mesh(
      new window.THREE.BoxGeometry(scale.x, scale.y, scale.z),
      material
    );
    box.position.set(position.x, position.y, position.z);
    return box;
  };

  checkInPortalSpace = (object) => {
    let cameraChildPositionCopy = new window.THREE.Vector3(
      this.camera.position.x,
      this.camera.position.y,
      this.camera.position.z
    );
    object.worldToLocal(cameraChildPositionCopy);
    //this.portalSpace.copy( this.storeCollisionMesh.geometry.boundingBox ).applyMatrix4( this.storeCollisionMesh.matrixWorld )
    //the above code update the collision mesh box info, need to find the right place for it
    //current is calling it in init function, which didn't update the beginning rotation of the store
    //that's why compare x with z in the following code
    this.isInPortalSpace =
      cameraChildPositionCopy.x > -this.spaceSize.x / 2 &&
      cameraChildPositionCopy.x < this.spaceSize.x / 2 &&
      cameraChildPositionCopy.z > -this.spaceSize.z &&
      cameraChildPositionCopy.z < 0;
    return this.isInPortalSpace;
  };

  checkForCollision(box3) {
    let hasIntersected = false;

    this.storeCollisionMesh.updateMatrix();
    this.storeCollisionMesh.updateMatrixWorld();
    this.storeCollisionMesh.geometry.faces.forEach((f) => {
      let a = new window.THREE.Vector3()
        .copy(this.storeCollisionMesh.geometry.vertices[f.a])
        .applyMatrix4(this.storeCollisionMesh.matrixWorld);
      let b = new window.THREE.Vector3()
        .copy(this.storeCollisionMesh.geometry.vertices[f.b])
        .applyMatrix4(this.storeCollisionMesh.matrixWorld);
      let c = new window.THREE.Vector3()
        .copy(this.storeCollisionMesh.geometry.vertices[f.c])
        .applyMatrix4(this.storeCollisionMesh.matrixWorld);
      let faceTriangle = new window.THREE.Triangle(a, b, c);

      let intersects = faceTriangle.intersectsBox(box3);
      if (!hasIntersected && intersects) {
        hasIntersected = true;
      }
    });

    return hasIntersected;
  }

  updatePortalTransform = () => {
    if (this.pivotRotationSpeed) {
      let rotationPivot = this[`rotationPivot${this.activeRotationPivot}`];

      let rotationDegrees = rotationPivot.rotation.y * (180 / Math.PI);
      rotationPivot.rotation.set(
        0,
        (rotationDegrees + this.pivotRotationSpeed || 0) * (Math.PI / 180),
        0
      );
      rotationPivot.updateMatrixWorld();
    }
    this.userCollisionMesh.position.copy(this.cameraProxy.position);
    this.userCollisionMesh.rotation.copy(this.cameraProxy.rotation);
    var userCollisionBox = new window.THREE.Box3().setFromObject(
      this.userCollisionMesh
    );

    if (this.checkForCollision(userCollisionBox)) {
      if (this.storeTranslationSpeed) {
        // if we are colliding, apply the movement to the portal and check collision again
        // if it collides, negate the movement and apply it to the portal.

        // scale the move a bit because they can be 'wedged' into the collision mesh some
        // move the moveCheck away from the camera instead of towards it
        let newTranslationSpeed = this.storeTranslationSpeed * 2;
        this.wholePortal.translateOnAxis(
          this.storeTranslateDirection,
          newTranslationSpeed * (this.deltaTime / 1000)
        );

        this.wholePortal.updateMatrixWorld();

        if (this.checkForCollision(userCollisionBox)) {
          this.storeTranslateDirection.negate();
          this.wholePortal.translateOnAxis(
            this.storeTranslateDirection,
            newTranslationSpeed * (this.deltaTime / 1000)
          );
          return;
        }

        // if the movement is good, allow it
        this.wholePortal.translateOnAxis(
          this.storeTranslateDirection,
          this.storeTranslationSpeed * (this.deltaTime / 1000)
        );
      } else {
        return;
      }
    } else {
      this.cameraChild.getWorldPosition(this.cameraChildPosition);
      this.cameraProxy.getWorldQuaternion(this.cameraWorldDirection);

      if (this.storeTranslationSpeed) {
        this.wholePortal.translateOnAxis(
          this.storeTranslateDirection,
          this.storeTranslationSpeed * (this.deltaTime / 1000)
        );
      }

      if (this.activeRotationPivot === "One") {
        this.rotationPivotTwo.position.copy(this.cameraChildPosition);
      } else {
        this.rotationPivotOne.position.copy(this.cameraChildPosition);
      }
    }
  };

  checkShowroomObjectGaze() {
    let {
      UI: { objectGazed, selectedSmartObject },
    } = store.getState();
    this.cursorRaycaster.setFromCamera(this.cursorPosition, this.camera);
    var intersects = this.cursorRaycaster.intersectObjects(
      this.floorObjectsCollision,
      true
    );
    if (intersects[0]) {
      if (intersects[0].object.userData.smartObject && !selectedSmartObject) {
        if (this.highlightedObject) {
          if (
            this.highlightedObject.userData.smartObject !==
            intersects[0].object.userData.smartObject
          ) {
            this.highlightedObject.userData.smartObject.hideBoundingBox();
            this.highlightedObject.userData.smartObject.worldText.visible = false;
          }
        }

        this.highlightedObject = intersects[0].object;

        this.highlightedObject.userData.smartObject.showBoundingBox();
        this.highlightedObject.userData.smartObject.worldText.visible = true;

        if (!objectGazed) {
          store.dispatch(UIActions.updateObjectGazed(true));
        }
      }
    } else {
      if (this.highlightedObject) {
        this.highlightedObject.userData.smartObject.hideBoundingBox();
        this.highlightedObject.userData.smartObject.worldText.visible = false;

        this.highlightedObject = null;
        if (objectGazed) {
          store.dispatch(UIActions.updateObjectGazed(false));
        }
      }
    }
  }

  update(deltaTime, time, pose) {
    this.lastPose = pose;
    this.deltaTime = deltaTime;
    Tween.update(time);
    if (this.showroom) {
      if (!this.desktop) {
        this.updatePortalTransform();
      }
      forEach(this.floorSmartObjects, (object) => {
        forEach(object, (item) => {
          item.update(deltaTime, time, this.app.hitPosition.position);
        });
      });
      this.checkShowroomObjectGaze();
    } else {
      forEach(this.productView.objectsAdded, (object) => {
        forEach(object, (item) => {
          item.update(deltaTime, time, this.app.hitPosition.position);
        });
      });
    }
  }
}
