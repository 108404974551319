import { SERVER_ENDPOINT } from "../constants";
import $ from "jquery";

export default {
  getProducts(accountID, productIds) {
    const deferred = new $.Deferred();
    const data = {
      AID: accountID,
      PIDs: productIds.map((product) => product.pid.productID).join(","),
    };
    $.ajax({
      url: SERVER_ENDPOINT + "/api/customer/products",
      method: "GET",
      data: data,
      contentType: "application/json; charset=utf-8",
    })
      .done((resp) => {
        deferred.resolve(resp);
      })
      .fail((err) => {
        deferred.reject(err);
      });
    return deferred;
  },
  createCheckoutSession(returnedWeb) {
    const deferred = new $.Deferred();
    const data = {
      returned_web: returnedWeb,
    };
    $.ajax({
      url: SERVER_ENDPOINT + "/api/checkout/session",
      method: "POST",
      data: JSON.stringify(data),
      contentType: "application/json; charset=utf-8",
    })
      .done((resp) => {
        deferred.resolve(resp);
      })
      .fail((err) => {
        deferred.reject(err);
      });
    return deferred;
  },
};
