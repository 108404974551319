import React, { Component } from "react";
import _ from "lodash";
import connect from "react-redux/es/connect/connect";

class SideMenu extends Component {
  constructor(props) {
    super();
    this.over = false; //GC avoidance
    this.overX = false; //GC avoidance
  }

  // TODO: there is less gracefull but more efficient ways of doing
  // this than using setState.
  state = {
    top: 0,
    left: 0,
    init: false,
    panX: -1,
    panY: -1,
    itemRects: [],
    activeItemIndex: null,
  };

  setPanXY(x, y) {
    this.setState({
      panX: x,
      panY: y,
    });
  }

  panEnd = () => {
    // if (this.state.activeItemIndex !== null) {
    //     this.props.selectedSmartObject.setObjectState(this.state.activeItemIndex)
    // } else {
    //     this.props.selectedSmartObject.revertToPreviousState()
    // }
    this.setState({
      panX: -1,
      panY: -1,
      activeItemIndex: null,
    });
  };

  componentDidMount() {
    console.log("side menu mount!!!!");
    this.setState(
      {
        rect: this.refs["sidemenu-container"].getBoundingClientRect(),
      },
      () => {
        let itemRects = [];
        _.forEach(this.props.items, (item, index) => {
          itemRects.push(
            this.refs[`sidemenu-item-${index}`].getBoundingClientRect()
          );
        });
        this.setState({
          itemRects: itemRects,
        });
      }
    );
  }

  componentWillUpdate(nextProps, nextState) {
    if (!this.state.init) {
      let itemRects = [];
      _.forEach(this.props.items, (item, index) => {
        itemRects.push(
          this.refs[`sidemenu-item-${index}`].getBoundingClientRect()
        );
      });
      if (
        this.state.itemRects.length &&
        itemRects[0].y !== this.state.itemRects[0].y
      ) {
        this.setState({
          itemRects: itemRects,
          init: true,
        });
      }
    } else {
      if (
        nextState.panX !== this.state.panX &&
        nextState.panY !== this.state.panY &&
        this.state.panX !== -1 &&
        this.state.panY !== -1
      ) {
        let i = -1;
        let over = false;
        _.forEach(this.props.items, (b) => {
          i++;
          this.overX = this.state.rect
            ? this.state.panX >= this.state.rect.x - 50
            : false;
          this.over = this.state.itemRects
            ? this.state.panX >= this.state.itemRects[i].x - 90 &&
              Math.abs(this.state.panY - this.state.itemRects[i].y - 20) <= 35
            : false;
          if (this.over && this.overX) {
            if (!over) {
              over = true;
            }
            if (this.state.activeItemIndex !== i) {
              this.setState(
                {
                  activeItemIndex: i,
                },
                () => {
                  this.props.bitreelMain.store.floorSmartObjects[
                    this.props.selectedSmartObject[0]
                  ][this.props.selectedSmartObject[1]].setObjectState(
                    this.state.activeItemIndex,
                    true
                  );
                  //this.props.selectedSmartObject.setObjectState(this.state.activeItemIndex, true)
                }
              );
            }
          }
          if (!over) {
            this.setState({
              activeItemIndex: null,
            });
          }
        });
      }
    }
  }

  renderSideItems = () => {
    return _.map(this.props.items, (b, index) => {
      return (
        <div
          className="hud-element"
          key={index}
          onClick={() => {
            this.props.bitreelMain.store.productView.objectsAdded[
              this.props.selectedSmartObject.productID
            ][this.props.selectedSmartObject.hashID].setObjectState(index);
            //this.props.selectedSmartObject.setObjectState(index)
          }}
          style={itemStyles(index, this.state, this.props, this)}
          ref={`sidemenu-item-${index}`}
        >
          <div className="product-state-thumb">
            <img src={b.thumbUrl} style={{ width: "100%" }} />
          </div>
        </div>
      );
    });
  };

  render() {
    return (
      //   <div style={styles(this.props, this.state, this).container} ref='sidemenu-touch'>
      <div
        ref="sidemenu-container"
        style={styles(this.props, this.state, this).sidemenu}
      >
        {this.renderSideItems()}
      </div>
      //   </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  selectedSmartObject: state.UI.selectedSmartObject,
  productData:
    state.store.storeConfig.sections[state.UI.currentSection].items[
      state.UI.selectedSmartObject
    ],
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);

const itemStyles = (idx, state, props, component) => {
  if (!state.itemRects.length) return {};

  component.overX =
    state.rect && state.panX !== -1 ? state.panX >= state.rect.x - 50 : false;
  component.over = state.itemRects
    ? state.panX >= state.itemRects[idx].x - 90 &&
      Math.abs(state.panY - state.itemRects[idx].y - 10) <= 35
    : false;
  return {
    position: "absolute",
    top: `${60 * idx}px`,
    right: 10,
    width: "40px",
    height: "40px",
    backgroundColor: component.over && component.overX ? "#ffffff" : "#ffffff",
    transition: "all 0.15s ease",
    transform:
      component.over && component.overX
        ? "translateX(-60px) scale(2.5)"
        : "translateX(0px) scale(1)",
    borderRadius: "50%",
    overflow: "hidden",
    display: state.rect ? "flex" : "none",
    alignItems: "center",
    justifyContent: "center",
  };
};

const styles = (props, state, component) => {
  if (!state.itemRects.length) return {};

  component.over = state.rect ? state.panX >= state.rect.x - 50 : false;
  return {
    sidemenu: {
      backgroundColor: "transparent",
      position: "absolute",
      right: component.over ? "0px" : "-75px",
      width: "75px",
      height: `${props.height}%`,
      top: `${(100 - props.height) / 2}%`,
      transition: "all 0.2s ease",
    },
    container: {
      backgroundColor: "transparent",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      pointerEvents: "none",
    },
  };
};
