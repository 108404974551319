import React, { Component } from "react";
import "./ActionsMenu.scss";

class ActionsMenu extends Component {
  state = {};

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        mounted: true,
      });
    }, 50);
  }

  render() {
    return (
      <div
        className={`actions-menu-container ${
          this.state.mounted ? "mounted" : ""
        }`}
      >
        {this.props.actions.map((a) => (
          <div onClick={a.onClick} className="actions-menu-container__inner">
            {a.text}
          </div>
        ))}
      </div>
    );
  }
}

export default ActionsMenu;
