import { OrbitControls } from "./utils/jsm/controls/OrbitControls";
import { Vector3 } from "./utils/jsm/three.module";

export default (startupFunction, main) => {
  let scene3;

  const scene = new window.THREE.Scene();
  scene.add(main.hitPosition);
  scene.background = 0xFFFFFF;
  const camera = new window.THREE.PerspectiveCamera(
    70,
    window.innerWidth / window.innerHeight,
    0.01,
    200
  );
  let cameraChild = new window.THREE.Object3D();
  camera.add(cameraChild);

  let container = document.createElement("div");
  container.setAttribute("id", "container");
  container.style = "width: 100vw; height: 100vh";
  var controls;
  if (main.customElement) {
    main.customElement.mountPoint.appendChild(container);
    controls = new OrbitControls(camera, main.customElement.mountPoint);
  } else {
    container.appendChild(document.getElementById("root"));
    document.body.appendChild(container);
    controls = new OrbitControls(camera, container);
  }
  let renderer = new window.THREE.WebGLRenderer({
    antialias: true,
    alpha: true,
  });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);

  // TODO: FIX THIS
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const storename = urlParams.get("store");

  if (storename !== "boconcept") {
    renderer.toneMapping = window.THREE.ACESFilmicToneMapping;
  }
  let cameraProxy = new window.THREE.Object3D();
  scene.add(cameraProxy);
  container.appendChild(renderer.domElement);
  renderer.domElement.setAttribute("id", "domElement");

  //const controls = new OrbitControls(camera, container);
  controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
  controls.dampingFactor = 0.05;
  controls.screenSpacePanning = false;

  controls.maxDistance = 500;

  controls.maxPolarAngle = Math.PI / 2;
  controls.enableKeys = true;

  var vector = new window.THREE.Vector3(0, 0, -1); // create once and reuse it!
  let target = new Vector3(
    camera.position.x + vector.x,
    camera.position.y + vector.y,
    camera.position.z + vector.z
  );
  controls.target = target;

  controls.addEventListener("change", () => {
    camera.getWorldDirection(vector);
    target.set(
      camera.position.x + vector.x,
      camera.position.y + vector.y,
      camera.position.z + vector.z
    );
    controls.target = target;
  });

  let gl = renderer.getContext();

  scene3 = {
    scene,
    camera,
    renderer,
    cameraChild,
    cameraProxy,
    gl,
    main,
    controls,
  };

  const onWindowResize = function () {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    renderer.setSize(window.innerWidth, window.innerHeight);
  };

  window.addEventListener("resize", onWindowResize, false);

  const render = () => {
    renderer.render(scene, camera);
  };

  window.bitreelModule = {
    xrScene: () => {
      return scene3;
    },
    render,
  };

  return new Promise((resolve, reject) => {
    startupFunction();
    resolve(scene3);
  });
};
