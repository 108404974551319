import React, { Component } from "react";
import { Tooltip, Button } from "antd";
import "./Tooltips.scss";

class Tooltips extends Component {
  state = {
    tipShowed: true,
  };
  clickHandler = () => {
    // this.state.tipShowed = false
    // localStorage.setItem('tipShowed', false)
  };

  componentDidMount() {
    const tipShowed = localStorage.getItem("tipShowed");
    this.state.tipShowed = tipShowed;
    setTimeout(() => {
      this.setState({
        tipShowed: true,
      });
      localStorage.setItem("tipShowed", true);
    }, 5000);
  }

  render() {
    const children = Array.isArray(this.props.children)
      ? this.props.children
      : [this.props.children];
    return (
      <div className={!this.state.tipShowed ? "tooltips" : null}>
        {/* {this.props.children ? children.map((child, index) => {
                    return (
                        <Tooltip key={index} placement={this.props.placement[index]} title={this.props.title[index]} visible = {this.state.i == index} arrowPointAtCenter>
                            {child}
                        </Tooltip>
                    )
                }):null} */}
        <Tooltip
          placement={this.props.placement}
          title={this.props.title}
          visible={!this.state.tipShowed}
          defaultVisible={false}
          arrowPointAtCenter
        >
          {this.props.children}
        </Tooltip>
      </div>
    );
  }
}

export default Tooltips;
