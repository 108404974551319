import $ from "jquery";
import axios from "axios";
import localConfig from "../hellmanchang-config-dev.json";

export default {
  getConfig(configName) {
    const deferred = new $.Deferred();
    axios
      .get(
        `https://bitreel-arshowrooms.s3.amazonaws.com/${configName}/${configName}-config${
          process.env.REACT_APP_DEV_BUILD ? "-dev" : ""
        }.json`
      )
      .then((resp) => {
        if (process.env.NODE_ENV === "production") {
          deferred.resolve(resp);
        } else {
          let config = localConfig;
          deferred.resolve({ data: config });
        }
      })
      .catch((err) => {
        deferred.reject(err);
      });
    return deferred;
  },
};
