import { Carousel } from "antd";
import React, { Component } from "react";
import "./ProductCarousel.scss";
import connect from "react-redux/es/connect/connect";
import { filter, forEach } from "lodash";
import UIActions from "../../redux/actions/UI";

class ProductCarousel extends Component {
  state = {
    mounted: true,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        mounted: true,
      });
    }, 50);
  }

  handleProductChange = () => {
    // switch item being previewed with raycast
  };

  renderProductCards = (products) => {
    if (!this.props.bitreelMain.store) {
      return null;
    }
    return products.map((p, i) => {
      return (
        <div
          style={{
            backgroundImage: `url('${p.imageUrl}')`,
          }}
          key={`carousel-item-${i}`}
          className="product-card hud-element"
          onClick={() => this.handleAddItem(p.name)}
        >
          <h3>${p.price}.00</h3>
        </div>
      );
    });
  };

  handleAddItem = (objectName) => {
    forEach(this.props.bitreelMain.store.floorSmartObjects, (object) => {
      forEach(object, (item) => {
        if (item.productData.name === objectName) {
          this.props.bitreelMain.store.addObjectToProductView(item);
          this.props.updateAddingProduct(false);
        }
      });
    });
  };

  render() {
    if (!this.props.bitreelMain.store) {
      return null;
    }

    let products = filter(this.props.products, (p) => {
      return p;
    });
    return (
      <div
        className={`product-carousel-container hud-element ${
          this.state.mounted ? "mounted" : ""
        }`}
      >
        <div className="product-carousel-container__inner">
          {products.length ? (
            <div>{this.renderProductCards(products)}</div>
          ) : (
            <h2>no more products available</h2>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  products: state.store.storeConfig.sections[state.UI.currentSection].items,
});

const mapDispatchToProps = (dispatch) => ({
  updateAddingProduct: (state) =>
    dispatch(UIActions.updateAddingProduct(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductCarousel);
