import React, { Component } from "react";
import { Slider } from "antd";
import "./ShowroomButton.scss";

class ShowroomButton extends Component {
  state = {
    active: false,
  };

  toggleActive = () => {
    this.setState({
      active: !this.state.active,
    });
  };

  render() {
    return (
      <div className="showroom-button hud-element">
        {this.props.useSlider && (
          <div
            className={`slider-wrapper ${this.state.active ? "active" : ""}`}
          >
            <div className="inner-wrapper">
              <Slider
                onChange={(v) => this.props.onSliderChange(v)}
                className="showroom-control-slider"
              />
            </div>
          </div>
        )}
        <span
          className={`icon-button ${this.state.active ? "active" : ""}`}
          onClick={
            this.props.useSlider ? this.toggleActive : this.props.onClick
          }
        >
          {this.props.icon}
        </span>
      </div>
    );
  }
}

export default ShowroomButton;
