import React, { Component } from "react";
import "./LoadingScreen.scss";
import connect from "react-redux/es/connect/connect";
import { debugMessage } from "../../utils/utils";

class LoadingScreen extends Component {
  state = {
    started: false,
  };

  componentDidMount() {
    if (process.env.NODE_ENV === "production") {
      debugMessage("StartBitreel");
      this.props.startBitreel();
    }
  }
  componentWillUnmount() {
    debugMessage("Loading end");
  }

  render() {
    return (
      <div id="loadingscreen">
        <img src={this.props.logo} />
        <div className="container">
          <div className="cube-container">
            <div className="cube" style={{ marginBottom: "50px" }}>
              <div className="sides">
                <div className="top" />
                <div className="right" />
                <div className="bottom" />
                <div className="left" />
                <div className="front" />
                <div className="back" />
              </div>
            </div>
          </div>
          {this.props.enteredAR ? (
            <div className="text">Loading AR Showroom</div>
          ) : (
            <div
              className="text button"
              onClick={() => this.props.startBitreel()}
            >
              Enter AR
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  debugMessage(this);

  return {
    startBitreel: ownProps.startBitreel,
    enteredAR: state.UI.enteredAR,
    logo: state.store.storeConfig.meta.logoUrl,
    storeInfo: state.store.storeConfig,
  };
};

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(LoadingScreen);
