import React, { Component } from "react";
import { Modal } from "antd";
import { GOOGLE_QR_CODE_GENERATOR } from "../../constants";
class QRpopup extends Component {
  state = {
    visible: true,
  };
  render() {
    return (
      <Modal
        visible={this.state.visible}
        cancelButtonProps={{ disabled: true }}
        onOk={() => {
          this.props.customElement.unmountReactComonent();
        }}
        closable={false}
      >
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <div style={{ marginBottom: "20px", textAlign: "center" }}>
            Scan the QR code on your mobile device to visit the virtual
            showroom.
          </div>
          <img
            id="qrcode"
            src={
              GOOGLE_QR_CODE_GENERATOR +
              encodeURIComponent(window.location.href + "?launchViewer=true")
            }
            alt="Oops, something wrong!"
          />
        </div>
      </Modal>
    );
  }
}

export default QRpopup;
