const TWEEN = require("@tweenjs/tween.js");
class Tween {
  fadeAudio = (player, targetVolume, animationMillis) => {
    var volume = { x: player.getVolume() };

    var target = { x: targetVolume };
    new TWEEN.Tween(volume)
      .to(target, animationMillis)
      .easing(TWEEN.Easing.Linear.None) // Use an easing function to make the animation smooth.
      .onUpdate(() => {
        player.setVolume(volume.x);
      })
      .start(); // Start the tween immediately.
  };

  fadeAnimation = (
    model,
    targetOpacity = 1,
    startOpacity = 1,
    animationMillis
  ) => {
    //default start/target opacity = 1
    var opacity = { x: startOpacity };
    if (model.material) {
      opacity = { x: model.material.opacity };
    }
    var target = { x: targetOpacity };
    new TWEEN.Tween(opacity)
      .to(target, animationMillis)
      .easing(TWEEN.Easing.Linear.None) // Use an easing function to make the animation smooth.
      .onUpdate(() => {
        // change it's own opacity and all the child opacity
        model.traverse(function (node) {
          if (node.material) {
            node.material.opacity = opacity.x;
            if (opacity.x == 1) {
              node.material.transparent = false;
            } else {
              node.material.transparent = true;
            }
          }
        });
      })
      .start(); // Start the tween immediately.
  };

  update(deltaTime) {
    TWEEN.update(deltaTime);
  }
}

export default new Tween();
