import s3Service from "../../service/s3Service";
import stripeService from "../../service/stripeService";
import UIActions from "./UI";
import SmartObject from "../../core/SmartObject";
import { has, keys, map, flatten, uniq, forEach } from "lodash";
import { preload } from "../../utils/images";
import cryptoRandomString from "crypto-random-string";

export default {
  createStoreConfig(storeConfig) {
    return {
      type: "CREATE_STORE_CONFIG",
      storeConfig: storeConfig,
    };
  },
  isStoreReady(isReady) {
    return {
      type: "UPDATE_STORE_READY",
      storeReady: isReady,
    };
  },
  isStoreConfigLoading(loading) {
    return {
      type: "UPDATE_STORE_CONFIG_LOADING",
      loading: loading,
    };
  },
  isProductConfigLoading(loading) {
    return {
      type: "UPDATE_PRODUCT_CONFIG_LOADING",
      loading: loading,
    };
  },
  updateStoreConfig(key, value) {
    return {
      type: "UPDATE_STORE_CONFIG",
      key: key,
      value: value,
    };
  },
  updateProductsFromStripe(products) {
    return {
      type: "UPDATE_PRODUCTS_FROM_STRIPE",
      products: products,
    };
  },
  loadProductsForSection(sectionID, section, smartStore) {
    return (dispatch) => {
      forEach(section.items, async (product, index) => {
        let hashID = cryptoRandomString({ length: 10 });
        const newObject = new SmartObject(index, hashID, product, smartStore);
        await newObject.init();
        smartStore.floorSmartObjects[index] = {}; // init store object list
        smartStore.productView.objectsAdded[index] = {}; // init product objects list
        smartStore.floorSmartObjects[index][hashID] = newObject;
        smartStore.floorObjectsCollision.push(newObject.collisionMesh);
      });
      dispatch(UIActions.updateCurrentSection(sectionID));
    };
  },
  updateSwitchingMode(bool) {
    return {
      type: "UPDATE_SWITCHING_MODE",
      switchingMode: bool,
    };
  },
  updateConfigLoadTime(duration) {
    return {
      type: "UPDATE_CONFIG_LOAD_TIME",
      configLoadTime: duration,
    };
  },
  updatePlatform(platform) {
    return {
      type: "UPDATE_PLATFORM",
      platform: platform,
    };
  },
  fetchStoreConfig(storeName) {
    return (dispatch) => {
      dispatch(this.isStoreConfigLoading(true));
      const start = performance.now();
      s3Service
        .getConfig(storeName)
        .done((config) => {
          dispatch(this.updateConfigLoadTime(performance.now() - start));
          let productImages = map(
            config.data.sections[config.data.meta.defaultFloor].items,
            (product) => {
              return product.imageUrl;
            }
          );
          preload(productImages);

          dispatch(this.createStoreConfig(config.data));
          if (
            has(config, "data.sections") &&
            has(config, "data.ecomm.accountId")
          ) {
            const pids = map(
              uniq(
                flatten(
                  map(config.data.sections, (section) => {
                    return keys(section.items);
                  })
                )
              ),
              (pid) => {
                return { pid: pid };
              }
            );
            dispatch(
              this.fetchProductsFromStripe(config.data.ecomm.accountId, pids)
            );
          }
        })
        .fail((err) => {
          console.log("failed to get store config");
        })
        .always(() => {
          dispatch(this.isStoreConfigLoading(false));
        });
    };
  },

  fetchProductsFromStripe(aid, pids) {
    return (dispatch) => {
      dispatch(this.isProductConfigLoading(true));
      stripeService
        .getProducts(aid, pids)
        .done((res) => {
          let products = JSON.parse(res).products;
          dispatch(this.updateProductsFromStripe(products));
        })
        .fail((err) => {
          //TODO: need warning message
          console.log("failed to get products info: " + JSON.stringify(err));
        })
        .always(() => {
          dispatch(this.isProductConfigLoading(false));
        });
    };
  },
};
