import { setCameraRange } from "../utils/umbraUtils";

export default class UmbraManager {
  constructor(config, app) {
    this.umbraConfig = config;
    this.app = app;
    this.update = this.update.bind(this);
  }

  init = () => {
    console.warn("Three.js 8th started successfully. Initalizing Umbra");
    return new Promise(async (resolve, reject) => {
      const { camera, scene, renderer } = window.bitreelModule.xrScene();
      // this actually matters for our magic numbers later
      if (!this.app.xrMode) {
        camera.position.set(0, 0, 0);
      }

      this.umbra = await window.UmbraRuntime.initWithThreeJS(renderer, {
        wasmURL: "https://bitreeltest.imfast.io/umbra.wasm",
      });
      camera.umbraQuality = 0.5;

      console.warn("Umbra loaded");

      resolve(true);
    });
  };

  unloadUmbraScene = async () => {
    return new Promise((resolve, reject) => {
      // do stuff to unload umbrascene
      resolve(true);
    });
  };

  getUmbraScene = async (key, projectId, modelId, type) => {
    return new Promise((resolve, reject) => {
      let umbraScene;
      const { camera, scene, renderer } = window.bitreelModule.xrScene();
      try {
        umbraScene = this.umbra.createScene(
          "key=" + key + "&project=" + projectId + "&model=" + modelId,
          type
        );
        umbraScene.onConnected = () => {
          setCameraRange(camera, umbraScene);
          // remember to add to the three scene and position it etc !!
          resolve(umbraScene);
        };
      } catch (err) {
        console.log(err);
      }
    });
  };

  update(deltaTime) {
    if (this.umbra) {
      this.umbra.update();
    }
  }
}
