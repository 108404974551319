import React from "react";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import { Menu } from "antd";

import "./ShareButton.scss";
import cartActions from "../../../redux/actions/cart";
import UIActions from "../../../redux/actions/UI";

class ShareButton extends React.Component {
  static propTypes = {
    sharePopup: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.props.showSharePopup(!this.props.sharePopup);
  }

  render() {
    return (
      <div className="share-button" onClick={this.onClick}>
        <i
          className="fa fa-share-alt"
          style={{
            fontSize: "40px",
            transform: "translateX(3px)",
            color: "white",
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  sharePopup: state.UI.sharePopup,
});

const mapDispatchToProps = (dispatch) => ({
  showSharePopup: (show) => dispatch(UIActions.showSharePopup(show)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareButton);
