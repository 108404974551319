import products from "../actions/products";
import _ from "lodash";

const defaultState = {
  loading: false,
  productsConfig: null,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case "UPDATE_COLLISION_MESH": {
      return {
        ...state,
        [action.productID]: {
          ...state[action.productID],
          collisionMesh: action.collisionMesh,
        },
      };
    }
    case "UPDATE_DISPLAY_TEXT": {
      return {
        ...state,
        [action.productID]: {
          ...state[action.productID],
          displayText: action.displayText,
        },
      };
    }
    case "UPDATE_PRODUCT_COLLISION_GEO": {
      return {
        ...state,
        [action.productID]: {
          ...state[action.productID],
          productCollisionMesh: {
            ...state[action.productID].productCollisionMesh,
            geometry: action.geometry,
          },
        },
      };
    }
    case "UPDATE_PRODUCT_COLLISION_MESH": {
      return {
        ...state,
        [action.productID]: {
          ...state[action.productID],
          productCollisionMesh: action.productCollisionMesh,
        },
      };
    }
    case "UPDATE_PRODUCT_OUTLINE": {
      return {
        ...state,
        [action.productID]: {
          ...state[action.productID],
          productOutline: action.productOutline,
        },
      };
    }
    case "UPDATE_LOADING": {
      return {
        ...state,
        loading: action.loading,
      };
    }
    case "CREATE_PRODUCTS_CONFIG": {
      return {
        ...state,
        productsConfig: action.config,
      };
    }
    default:
      return state;
  }
}
