export default function () {
  this.raycaster = new window.THREE.Raycaster();
  this.tapPosition = new window.THREE.Vector2();

  this.update = this.update.bind(this);
  this.firstTime = true; // Check if it's the first time load the showroom view

  this.floorSmartObjects = {};
  this.floorObjectsCollision = [];

  this.threeFonts = {};
  this.wholePortal = new window.THREE.Object3D();
  this.wholePortal.position.set(0, -1.5, 0);
  this.portalContent = new window.THREE.Object3D();
  this.portalEntrance = new window.THREE.Object3D();
  this.portalDoor = new window.THREE.Object3D();
  this.portalWall = new window.THREE.Object3D();
  this.hiderWall = new window.THREE.Object3D();

  this.cameraWorldDirection = new window.THREE.Quaternion();

  this.cursorPosition = new window.THREE.Vector2();
  this.cursorRaycaster = new window.THREE.Raycaster();
  this.cursorPosition.x = (window.innerWidth / 2 / window.innerWidth) * 2 - 1;
  this.cursorPosition.y =
    -(window.innerHeight / 2 / window.innerHeight) * 2 + 1;

  this.storeVelocityX = 0;
  this.storeVelocityZ = 0;
  this.storeVelocity = new window.THREE.Vector3(
    this.storeVelocityX,
    0,
    this.storeVelocityZ
  );
  this.storeTranslateDirection = new window.THREE.Vector3();
  this.lastWholePortalWorldPosition = new window.THREE.Vector3();
  this.lastWholePortalPivotOffset = new window.THREE.Vector3();
  this.worldRotationEuler = new window.THREE.Euler();

  this.cameraChildPosition = new window.THREE.Vector3();

  this.rotationPivotOne = new window.THREE.Object3D();
  this.rotationPivotTwo = new window.THREE.Object3D();
  this.activeRotationPivot = "One";

  this.isInPortalSpace = true;
  this.wasOutside = true;
  this.offset = null;

  this.cameraHeightPositions = [];
  this.scaling = false;
  this.rotating = false;

  this.portalPosition = new window.THREE.Vector3(0, -1.5, 0);
  this.portalRotation = new window.THREE.Vector3(0, 0, 0);
  this.portalScale = new window.THREE.Vector3(1, 1, 1);

  this.deltaTime = 0;
  this.storeCollisionMesh = new window.THREE.Mesh();
  this.lastGoodPosition = new window.THREE.Vector3();
  this.lastGoodCameraPosition = new window.THREE.Vector3();
  // this.portalSpace = new window.THREE.Box3()

  this.userCollisionBox = new window.THREE.Box3();
  this.userCollisionBox.min = new window.THREE.Vector3(-0.2, -0.2, -0.2);
  this.userCollisionBox.max = new window.THREE.Vector3(0.2, 0.2, 0.2);

  this.moveCheck = new window.THREE.Object3D();
  this.lockModel = new window.THREE.Object3D();
  this.spaceSize = new window.THREE.Vector3();

  this.firstSelectedShowroomProduct = false;
  this.productView = {
    objectBeingPlaced: null,
    firstSelected: null,
    selectedProductViewItem: null,
    objectsAdded: {},
    objectsRaycastable: [],
    productPanSpeed: 3,
  };

  this.gestureState = {
    rotating: false,
    pinching: false,
    dragging: false,
    accumulatedRotation: 0,
    accumulatedScale: 0,
    accumulatedDrag: 0,
  };
}
