import React, { Component } from "react";
import "./Logo.scss";
import { BITREEL_LOGO } from "../../constants";

class Logo extends Component {
  state = {};

  render() {
    return (
      <div className="logo">
        <img src={BITREEL_LOGO} alt="Logo"></img>
      </div>
    );
  }
}

export default Logo;
