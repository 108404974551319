import Hammer from "hammerjs";

class InteractionManager {
  init = (app, selector, modeName, gestureFunctions) => {
    this.app = app;

    let square;
    if (app.customElement) {
      square = app.customElement.shadowRoot.querySelector(selector);
    } else {
      square = document.querySelector(selector);
    }
    this.hammer = new Hammer.Manager(square);

    this.hammerRotate = new Hammer.Rotate();
    this.hammerPinch = new Hammer.Pinch();
    this.hammerTap = new Hammer.Tap({ event: "singletap" });
    this.hammerDoubleTap = new Hammer.Tap({ event: "doubletap", taps: 2 });
    this.hammerPan = new Hammer.Pan();

    this.hammer.add([
      this.hammerDoubleTap,
      this.hammerRotate,
      this.hammerTap,
      this.hammerPan,
      this.hammerPinch,
    ]);

    this.hammer.get("singletap").requireFailure("doubletap");
    this.hammer.get("doubletap").dropRequireFailure("singletap");

    // TODO: move these out from right here
    this.adjustDeltaX = 0;
    this.adjustDeltaY = 0;
    this.adjustScale = 1;
    this.adjustRotation = 0;

    this.currentDeltaX = null;
    this.currentDeltaY = null;
    this.currentScale = 1;
    this.currentRotation = 0;

    // for later
    this.mode = modeName;
    switch (modeName) {
      case "showroom":
        // any custom showroom stuff
        break;
      case "product":
        // any custom product stuff
        break;
      default:
        return;
    }

    // todo: eventually these might have parameters
    gestureFunctions.forEach((fx) => fx());
  };

  onGesture = (gesture, gestureFunction) => {
    this.hammer.on(gesture, (ev) => {
      if (
        !this.hitTest(ev.target.classList) &&
        ev.target.offsetParent &&
        !this.hitTest(ev.target.offsetParent.classList)
      ) {
        ev.preventDefault();
        gestureFunction(ev);
      }
    });
  };

  clearGesture = (gesture) => {
    this.hammer.on(gesture, null);
  };

  disableHammer = () => {
    this.hammer.set({ enable: false });
  };

  hitTest(targetClassList) {
    if (targetClassList.contains("hud-element")) {
      return true;
    }
    return false;
  }
}

export default new InteractionManager();
