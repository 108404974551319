import React, { Component } from "react";
import "./ProductHud.scss";
import ProductCarousel from "../../components/ProductCarousel";
import SideMenu from "../../components/SideMenu";
import ScreenshotComponent from "../../components/ScreenshotComponent";
import connect from "react-redux/es/connect/connect";
import ReactGA from "react-ga";

class ProductHud extends Component {
  state = {};

  componentDidMount() {
    setTimeout(() => {
      this.setState(
        {
          mounted: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              mounted: false,
            });
          }, 2000);
        }
      );
    }, 750);

    //ga
    ReactGA.pageview("/product");
  }

  render() {
    const { bitreelMain } = this.props;

    return (
      <div className="product-hud">
        {this.props.addingProduct && (
          <ProductCarousel bitreelMain={bitreelMain} />
        )}
        {this.props.selectedSmartObject &&
          this.props.productData.umbra.productStates.length > 1 && (
            <SideMenu
              ref={(ref) => (this.sideMenu = ref)}
              items={this.props.productData.umbra.productStates}
              height={50}
              bitreelMain={this.props.bitreelMain}
            />
          )}
        {this.props.children}
        {!bitreelMain.xrMode && (
          <ScreenshotComponent
            icon={<i className="fa fa-camera" style={{ fontSize: "40px" }} />}
            download={
              <i className="fa fas-download" style={{ fontSize: "40px" }} />
            }
            close={<i className="fa fas-times" style={{ fontSize: "40px" }} />}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  selectedSmartObject: state.UI.selectedSmartObject,
  productData: state.UI.selectedSmartObject
    ? state.store.storeConfig.sections[state.UI.currentSection].items[
        state.UI.selectedSmartObject.productID
      ]
    : null,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ProductHud);
