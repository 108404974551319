import { Progress } from "antd";
import React, { Component } from "react";
import "./ItemLoading.scss";

class ItemLoading extends Component {
  state = {};
  render() {
    return (
      <div className="item-loading-container">
        <div className="loader">
          <div className="loaderBar" />
        </div>
        <div style={{ textAlign: "center" }}>Loading Product...</div>
      </div>
    );
  }
}

export default ItemLoading;
