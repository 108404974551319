const defaultState = {
  isDrawerOpen: false,
  itemsCount: 0,
  isProductLoading: false,
  isTaxLoading: false,
  products: null,
  tax: null,
  shipping: null,
  bitreel_e_commerce_products: [],
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case "ITEM_COUNT": {
      return {
        ...state,
        itemsCount: action.itemsCount,
      };
    }
    case "TAX_LOADING": {
      return {
        ...state,
        isTaxLoading: action.isTaxLoading,
      };
    }
    case "PRODUCT_LOADING": {
      return {
        ...state,
        isProductLoading: action.isProductLoading,
      };
    }
    case "DRAWER": {
      return {
        ...state,
        isDrawerOpen: action.isDrawerOpen,
      };
    }
    case "TAX_INFO": {
      return {
        ...state,
        tax: action.tax,
      };
    }
    case "PRODUCT_INFO": {
      return {
        ...state,
        products: action.products,
      };
    }
    case "ADD_PRODUCT_ID": {
      return {
        ...state,
        bitreel_e_commerce_products: [
          ...state.bitreel_e_commerce_products,
          { pid: action.pid },
        ],
      };
    }
    default:
      return state;
  }
}
