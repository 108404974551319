import store from "../../redux";
const getSelectedObject = function () {
  return store.getState().UI.selectedSmartObject;
};

const findFloorByName = function (floorName) {
  return this.storeConfig.sections[floorName];
  // TODO: return the SmartObject as well
};

const findItemByName = function (floorName, itemName) {
  let foundFloor = this.storeConfig.sections[floorName];
  let foundItem;
  if (foundFloor) {
    foundItem = foundFloor.items[itemName];
  }
  // TODO: return the SmartObject as well
  return foundItem;
};

export default {
  getSelectedObject,
  findFloorByName,
  findItemByName,
};
