import { debugMessage } from "./utils";

export function preload(imageArray, index = 0) {
  if (imageArray && imageArray.length > index) {
    var img = new Image();
    img.onload = function () {
      preload(imageArray, index + 1);
      debugMessage("loading image..");
    };
    img.src = imageArray[index];
  }
}
