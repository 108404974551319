import stripeService from "../../service/stripeService";

export default {
  addProduct(pid) {
    return {
      type: "ADD_PRODUCT_ID",
      pid: pid,
    };
  },
  updateLocalStorageCount(itemsCount) {
    return {
      type: "ITEM_COUNT",
      itemsCount: itemsCount,
    };
  },
  fetchProducts(aid, pids) {
    return (dispatch) => {
      dispatch(this.isProductLoading(true));
      stripeService
        .getProducts(aid, JSON.parse(pids))
        .done((products) => {
          dispatch(this.productInfo(JSON.parse(products).products));
        })
        .fail((err) => {
          //TODO: need warning message
          console.log("failed to get products info: " + JSON.stringify(err));
        })
        .always(() => {
          dispatch(this.isProductLoading(false));
        });
    };
  },

  productInfo(products) {
    return {
      type: "PRODUCT_INFO",
      products: products,
    };
  },
  isProductLoading(isLoading) {
    return {
      type: "PRODUCT_LOADING",
      isProductLoading: isLoading,
    };
  },
  openDrawer() {
    return {
      type: "DRAWER",
      isDrawerOpen: true,
    };
  },
  closeDrawer() {
    return {
      type: "DRAWER",
      isDrawerOpen: false,
    };
  },
};
