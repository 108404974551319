function joystickControlStart() {
  // move the none active pivot to the camera's position
  this.cameraChild.getWorldPosition(this.cameraChildPosition);

  if (this.activeRotationPivot === "One") {
    this.rotationPivotTwo.position.copy(this.cameraChildPosition);
    this.rotationPivotTwo.rotation.copy(this.rotationPivotOne.rotation);
    this.rotationPivotTwo.attach(this.wholePortal);
    this.activeRotationPivot = "Two";
  } else {
    this.rotationPivotOne.position.copy(this.cameraChildPosition);
    this.rotationPivotOne.rotation.copy(this.rotationPivotTwo.rotation);
    this.rotationPivotOne.attach(this.wholePortal);
    this.activeRotationPivot = "One";
  }
}

function joystickReleaseStore() {
  this.cameraChild.getWorldPosition(this.cameraChildPosition);

  if (this.activeRotationPivot === "One") {
    this.rotationPivotTwo.position.copy(this.cameraChildPosition);
    this.rotationPivotTwo.rotation.copy(this.rotationPivotOne.rotation);
    this.rotationPivotTwo.attach(this.wholePortal);
    this.activeRotationPivot = "Two";
  } else {
    this.rotationPivotOne.position.copy(this.cameraChildPosition);
    this.rotationPivotOne.rotation.copy(this.rotationPivotTwo.rotation);
    this.rotationPivotOne.attach(this.wholePortal);
    this.activeRotationPivot = "One";
  }

  this.storeTranslationSpeed = 0;
  this.pivotRotationSpeed = 0;
  this.storeTranslateDirection = null;
}

function joystickTranslateStore(moveData) {
  const rotateSpeed =
    moveData.vector.x *
    (20 * (moveData.distance / 40)) *
    (this.deltaTime / 1000);

  if (moveData.vector.y < -0.4 || moveData.vector.y > 0.4) {
    this.storeTranslateDirection = new window.THREE.Vector3();

    let forwardAngle = (moveData.angle.degree - 90) * -1; // After this 0 is UP. Right is 90
    forwardAngle = forwardAngle * (Math.PI / 180); // convert to radians

    let cameraVelocity = 2;

    let totalVelocity = cameraVelocity * moveData.vector.y;
    let { y: vertDir } = moveData.direction;

    this.storeTranslationSpeed =
      vertDir === "up" ? totalVelocity : -totalVelocity;
    const { camera, cameraChild } = window.bitreelModule.xrScene();

    // first copy or clone since we don't want to rotate the real XR camera
    let cameraClone = new window.THREE.Object3D();
    cameraClone.copy(camera);
    // in WebXR case, only the child of camera works for some reason
    cameraClone = cameraClone.children[0];

    // rotate the camera in the Y axis the same angle as the joystick with straight up
    // being 0
    cameraClone.rotateY(-forwardAngle);

    // get the direction the now slightly rotated camera is looking
    cameraClone.getWorldDirection(this.storeTranslateDirection);

    // applyEuler below: apply the inverse of the store's current rotation
    // pivot to the camera direction. After, this means
    // storeTranslateDirection is now a direction aligned with the store
    // and pointed at the camera (+/- how much forward vector is applied)

    // storeTranslateDirection is used in updateStoreTransforms, the store translated
    // in the direction with .translateOnAxis
    let rotationPivot = this[`rotationPivot${this.activeRotationPivot}`];
    this.storeTranslateDirection.applyEuler(
      new window.THREE.Euler(
        -rotationPivot.rotation.x,
        -rotationPivot.rotation.y,
        -rotationPivot.rotation.z,
        "XYZ"
      )
    );

    // cancel out the y translation so store does not go up
    // TODO: footstep bumps
    this.storeTranslateDirection.y = 0;

    // if we are translating forward, stop rotating
    this.pivotRotationSpeed = 0;
  } else if (moveData.vector.x < -0.1 || moveData.vector.x >= 0.1) {
    // if we are rotating, stop traslating
    this.storeTranslationSpeed = 0;
    this.pivotRotationSpeed = rotateSpeed;
  } else {
    this.storeTranslationSpeed = 0;
    this.pivotRotationSpeed = 0;
  }
}

export default {
  joystickControlStart,
  joystickReleaseStore,
  joystickTranslateStore,
};
