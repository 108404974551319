import { combineReducers } from "redux";
import cart from "./cart";
import store from "./store";
import UI from "./UI";
import products from "./products";

const reducers = combineReducers({
  cart: cart,
  store: store,
  UI: UI,
  products: products,
});

export default reducers;
