import stripeService from "../../service/stripeService";
import s3Service from "../../service/s3Service";
import { debugMessage } from "../../utils/utils";

export default {
  findCollisionMesh(collisionScene, itemData, productID) {
    return (dispatch) => {
      collisionScene.traverse((obj) => {
        if (
          obj.userData &&
          obj.userData.name &&
          obj.userData.name.indexOf(itemData.shortName) !== -1
        ) {
          return obj;
        } else {
          debugMessage("No collision mesh found");
        }
      });
    };
  },
  createProductsConfig(config) {
    return {
      type: "CREATE_PRODUCTS_CONFIG",
      config: config,
    };
  },
  isLoading(loading) {
    return {
      type: "UPDATE_LOADING",
      loading: loading,
    };
  },
  updateCollisionMesh(collisionScene, itemData, productID) {
    return (dispatch) => {
      collisionScene.traverse((obj) => {
        if (
          obj.userData &&
          obj.userData.name &&
          obj.userData.name.indexOf(itemData.shortName) !== -1
        ) {
          return dispatch({
            type: "UPDATE_COLLISION_MESH",
            collisionMesh: obj,
            productID: productID,
          });
        }
      });
    };
  },
  updateDisplayText(displayText, productID) {
    return {
      type: "UPDATE_DISPLAY_TEXT",
      displayText: displayText,
      productID: productID,
    };
  },
  updateProductOutline(collisionMesh, productID) {
    var geoEdges = new window.THREE.EdgesGeometry(collisionMesh.geometry);
    var collisionMeshOutline = new window.THREE.LineSegments(
      geoEdges,
      new window.THREE.LineBasicMaterial({ color: "#32CD32" })
    );
    return {
      type: "UPDATE_PRODUCT_OUTLINE",
      productOutline: collisionMeshOutline,
      productID: productID,
    };
  },
  updateProductCollisionMesh(collisionScene, itemData, productID) {
    return (dispatch) => {
      collisionScene.traverse((obj) => {
        if (
          obj.userData &&
          obj.userData.name &&
          obj.userData.name.indexOf(itemData.shortName) !== -1
        ) {
          return dispatch({
            type: "UPDATE_PRODUCT_COLLISION_MESH",
            productCollisionMesh: obj,
            productID: productID,
          });
        }
      });
    };
  },
  updateProductGeo(productID, geometry) {
    return {
      type: "UPDATE_PRODUCT_COLLISION_GEO",
      geometry: geometry,
      productID: productID,
    };
  },
  fetchProductsConfig(configName) {
    return (dispatch) => {
      dispatch(this.isLoading(true));
      s3Service
        .getConfig(configName)
        .done((config) => {
          dispatch(this.createProductsConfig(config.data));
        })
        .fail((err) => {
          debugMessage("failed to get store config");
        })
        .always(() => {
          dispatch(this.isLoading(false));
        });
    };
  },
};
