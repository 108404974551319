import React, { Component } from "react";
import { Menu, Dropdown, Badge } from "antd";
import cartActions from "../../../redux/actions/cart";
import connect from "react-redux/es/connect/connect";
import "./ViewerMenu.scss";
import ReactGA from "react-ga";
import { STORAGE_KEY } from "../../../constants";
const { SubMenu } = Menu;

class ViewerMenu extends Component {
  constructor(props) {
    super(props);
    this.onMenuClick = this.onMenuClick.bind(this);
  }
  renderMenuItems = () => {
    return (
      <Menu>
        {this.props.children.map((c, i) => {
          return <Menu.Item key={`viewer-menu-option-${i}`}>{c}</Menu.Item>;
        })}
      </Menu>
    );
  };

  onMenuClick() {
    ReactGA.event({
      category: "Button",
      action: "Click",
      label: "Viewer Menu",
    });
  }
  componentDidMount() {
    const items = localStorage.getItem(STORAGE_KEY);
    if (items !== null) {
      this.props.updateLocalStorageCount(JSON.parse(items).length);
    }
  }

  render() {
    return (
      <div className="viewer-menu hud-element">
        <Dropdown
          getPopupContainer={() => this.props.mountPoint}
          placement="bottomCenter"
          overlay={this.renderMenuItems()}
          trigger={["click"]}
          onClick={this.onMenuClick}
        >
          <a
            className="ant-dropdown-link hud-element"
            onClick={(e) => e.preventDefault()}
          >
            <Badge count={this.props.cartItemCount} dot={true}>
              {this.props.icon}
            </Badge>
          </a>
        </Dropdown>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  cartItemCount: state.cart.itemsCount,
});

const mapDispatchToProps = (dispatch) => ({
  updateLocalStorageCount: (num) =>
    dispatch(cartActions.updateLocalStorageCount(num)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewerMenu);
